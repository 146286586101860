<template>
  <div class="home">
    <!-- ナビメニュー -->
    <v-navigation-drawer app clipped>
      <patrolReportNavigationMenu
        v-on:patrolReport="moveToPatrolReport"
        v-on:moneyCheck="moveToMoneyCheck"
        v-on:stockCheck="moveToStockCheck"
      >
      </patrolReportNavigationMenu>
    </v-navigation-drawer>
    <p>GM巡回レポート</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <!-- <v-row>
            <v-col cols="12" sm="12" md="4" lg="4">
              <div
                label=""
                :disabled="allDisabled11969"
                background-color="white"
                id="FID11876"
                class="plainText11876 subtitle-1"
                hide-details="auto"
              >
                ■行動指針・重点行動
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                label="行動指針"
                v-model="data.Recodes.FID11227.value"
                :disabled="allDisabled11969"
                :rules="[rules.required]"
                background-color="white"
                id="FID11227"
                class="textfield11227"
                outlined
                hide-details="auto"
                :hint="hint11227"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-menu
                v-model="data.Recodes.FID11758.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="巡回日"
                    v-model="data.Recodes.FID11758.value"
                    :disabled="
                      data.Recodes.FID11758.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11758"
                    class="inputTime11758"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    @click:append="on.click"
                    readonly
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.Recodes.FID11758.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID11758.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <div v-show="false">
              {{ calc11873sp(data.Recodes.FID11873, data.Recodes) }}
            </div>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="巡回開始"
                :disabled="data.Recodes.FID11759.disabled || allDisabled11969"
                background-color="white"
                id="FID11759"
                class="button11759"
                @click="onBtn11759"
                color="primary white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                巡回開始
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="巡回終了"
                :disabled="data.Recodes.FID11760.disabled || allDisabled11969"
                background-color="white"
                id="FID11760"
                class="button11760"
                @click="onBtn11760"
                color="red white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                巡回終了
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="巡回開始時刻"
                :disabled="data.Recodes.FID11761.disabled || allDisabled11969"
                :rules="[rules.required]"
                background-color="white"
                id="FID11761"
                class="textfield11761"
                outlined
                readonly
                :value="calc11761sp(data.Recodes.FID11761, data.Recodes)"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="巡回終了時刻"
                :disabled="data.Recodes.FID11762.disabled || allDisabled11969"
                background-color="white"
                id="FID11762"
                class="textfield11762"
                outlined
                readonly
                :value="calc11762sp(data.Recodes.FID11762, data.Recodes)"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="SSコード"
                v-model="data.Recodes.FID11763.value"
                :disabled="data.Recodes.FID11763.disabled || allDisabled11969"
                background-color="white"
                id="FID11763"
                class="textfield11763"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="担当GM"
                v-model="data.Recodes.FID11764.value"
                :disabled="data.Recodes.FID11764.disabled || allDisabled11969"
                background-color="white"
                id="FID11764"
                class="textfield11764"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="グループ"
                v-model="data.Recodes.FID11765.value"
                :disabled="data.Recodes.FID11765.disabled || allDisabled11969"
                background-color="white"
                id="FID11765"
                class="textfield11765"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="GM（巡回者）"
                v-model="data.Recodes.FID11766.value"
                :disabled="allDisabled11969"
                :background-color="inputColor(data.Recodes.FID11766)"
                id="FID11766"
                class="userInfo11766"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="SS名"
                v-model="data.Recodes.FID11767.value"
                :disabled="data.Recodes.FID11767.disabled || allDisabled11969"
                background-color="white"
                id="FID11767"
                class="textfield11767"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="MG"
                v-model="data.Recodes.FID11768.value"
                :disabled="data.Recodes.FID11768.disabled || allDisabled11969"
                background-color="white"
                id="FID11768"
                class="textfield11768"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="SS対応者名（面談者名）"
                v-model="data.Recodes.FID11770.value"
                :disabled="allDisabled11969"
                :rules="[rules.required]"
                :background-color="inputColor(data.Recodes.FID11770)"
                id="FID11770"
                class="textfield11770"
                outlined
                hide-details="auto"
                :hint="hint11770"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-btn
                label="情報入力完了"
                :disabled="
                  data.Recodes.FID11864.disabled ||
                  allDisabled11969 ||
                  !data.Recodes.FID11761.value
                "
                background-color="white"
                id="FID11864"
                class="button11864"
                @click="onBtn11864"
                color="primary white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                情報入力完了
              </v-btn>
            </v-col>
          </v-row>
          <!-- 対象外機能 -->
          <div v-show="data.Recodes.FID11864.value != ''">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <patrolreportCreateSpecial
                  label=""
                  :disabled="allDisabled11969"
                  background-color="white"
                  id="FID11840"
                  class=""
                  :data="data"
                  :pageID="1651"
                  hide-details="auto"
                  ref="patrolreportCreateSpecial"
                ></patrolreportCreateSpecial>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11969"
                  background-color="white"
                  id="FID11783"
                  class="divider11783"
                  :style="'border-bottom-width: 3px !important;'"
                  color="black"
                  hide-details="auto"
                ></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                class="text-center align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11969"
                  background-color="white"
                  id="FID11784"
                  class="plainText11784 subtitle-1"
                  hide-details="auto"
                >
                  項目：確認・指導ポイント
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  background-color="white"
                  :style="'border-bottom-width: 3px !important;'"
                  color="black"
                  hide-details="auto"
                ></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="0">
                <v-select
                  label="点検項目"
                  v-model="inspectionItem"
                  :rules="[rules.required]"
                  background-color="white"
                  outlined
                  no-data-text="データが見つかりません"
                  :items="inspectionItems"
                  item-text="label"
                  item-value="value"
                  hide-details="auto"
                  @change="handleInspectionItemChange"
                >
                </v-select>
              </v-col>
            </v-row>
            <template v-if="isShowInspection1">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11785"
                    class="divider11785"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11786"
                    class="plainText11786 text-body-1"
                    hide-details="auto"
                  >
                    1.第一印象・クレンリネス＆掲示物（フィールド/ゲストルーム/スタッフルーム/ピット）
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea
                    label="テキスト"
                    v-model="data.Recodes.FID11787.value"
                    :disabled="allDisabled11969"
                    :background-color="inputColor(data.Recodes.FID11787)"
                    id="FID11787"
                    class="textfield11787"
                    outlined
                    rows="3"
                    auto-grow
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11811"
                    class="plainText11811 text-caption"
                    :class="classRequired(data.Recodes.FID11788)"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID11788.value"
                    :disabled="
                      data.Recodes.FID11788.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11788"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID11788.value == '〇'
                          ? data.Recodes.FID11788.color
                          : data.Recodes.FID11788.disableColor
                      "
                      :disabled="data.Recodes.FID11788.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11788.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID11788.value == '×'
                          ? data.Recodes.FID11788.color
                          : data.Recodes.FID11788.disableColor
                      "
                      :disabled="data.Recodes.FID11788.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11788.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11812"
                    class="plainText11812 text-caption"
                    :class="classRequired(data.Recodes.FID11789)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID11789.value"
                    :disabled="
                      data.Recodes.FID11789.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11789"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID11789.value == '〇'
                          ? data.Recodes.FID11789.color
                          : data.Recodes.FID11789.disableColor
                      "
                      :disabled="data.Recodes.FID11789.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11789.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID11789.value == '×'
                          ? data.Recodes.FID11789.color
                          : data.Recodes.FID11789.disableColor
                      "
                      :disabled="data.Recodes.FID11789.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11789.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="showImage1"
                    label="画像表示"
                    hide-details
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="showImage1">
                <v-row
                  v-if="
                    typeof data.Recodes.FID11836.subReports != 'undefined' &&
                    data.Recodes.FID11836.subReports.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    v-for="(report, i) in data.Recodes.FID11836.subReports"
                    :key="`[11836-${i}`"
                  >
                    <subPagePatrolUpload
                      label=""
                      :disabled="allDisabled11969"
                      background-color="white"
                      :id="'FID11836-' + i"
                      class="subPage11836"
                      ref="subPage11836"
                      :Report="report"
                      :subindex="i"
                      :daleteReports.sync="data.DeleteSubReports"
                      v-on:clickSubmit="saveSubPage"
                      :myInit="subInit"
                      FID="FID11836"
                      hide-details="auto"
                    ></subPagePatrolUpload>
                    <v-col align="right">
                      <v-btn
                        @click="changeSubPageFID11836(i, i + 1)"
                        color="green"
                        class="mt-2 mp-2"
                        :disabled="i + 1 >= data.Recodes.FID11836.subReports.length"
                      >
                        ↓
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <v-btn
                      @click="subPageDel('FID11836')"
                      :disabled="allDisabled11969"
                      color="orange white--text"
                      class="ma-2"
                    >
                      画像削除
                    </v-btn>
                    <v-btn
                      @click="subPageAdd('FID11836')"
                      :disabled="allDisabled11969"
                      color="light-blue white--text"
                      class="ma-2"
                    >
                      画像追加
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template v-if="isShowInspection2">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11790"
                    class="divider11790"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11791"
                    class="plainText11791 text-body-1"
                    hide-details="auto"
                  >
                    2.設備（固定資産/リース/社有車/各種設備/PC/消防関連）
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea
                    label="テキスト"
                    v-model="data.Recodes.FID11792.value"
                    :disabled="allDisabled11969"
                    :background-color="inputColor(data.Recodes.FID11792)"
                    id="FID11792"
                    class="textfield11792"
                    outlined
                    rows="3"
                    auto-grow
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11813"
                    class="plainText11813 text-caption"
                    :class="classRequired(data.Recodes.FID11793)"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID11793.value"
                    :disabled="
                      data.Recodes.FID11793.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11793"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint11793"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID11793.value == '〇'
                          ? data.Recodes.FID11793.color
                          : data.Recodes.FID11793.disableColor
                      "
                      :disabled="data.Recodes.FID11793.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11793.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID11793.value == '×'
                          ? data.Recodes.FID11793.color
                          : data.Recodes.FID11793.disableColor
                      "
                      :disabled="data.Recodes.FID11793.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11793.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11814"
                    class="plainText11814 text-caption"
                    :class="classRequired(data.Recodes.FID11794)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID11794.value"
                    :disabled="
                      data.Recodes.FID11794.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11794"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint11794"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID11794.value == '〇'
                          ? data.Recodes.FID11794.color
                          : data.Recodes.FID11794.disableColor
                      "
                      :disabled="data.Recodes.FID11794.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11794.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID11794.value == '×'
                          ? data.Recodes.FID11794.color
                          : data.Recodes.FID11794.disableColor
                      "
                      :disabled="data.Recodes.FID11794.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11794.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="showImage2"
                    label="画像表示"
                    hide-details
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="showImage2">
                <v-row
                  v-if="
                    typeof data.Recodes.FID13630.subReports != 'undefined' &&
                    data.Recodes.FID13630.subReports.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    v-for="(report, i) in data.Recodes.FID13630.subReports"
                    :key="`[13630-${i}`"
                  >
                    <subPagePatrolUpload2
                      label=""
                      :disabled="allDisabled11969"
                      background-color="white"
                      :id="'FID13630-' + i"
                      class="subPage13630"
                      ref="subPage13630"
                      :Report="report"
                      :subindex="i"
                      :daleteReports.sync="data.DeleteSubReports"
                      v-on:clickSubmit="saveSubPage"
                      :myInit="subInit"
                      FID="FID13630"
                      hide-details="auto"
                    ></subPagePatrolUpload2>
                    <v-col align="right">
                      <v-btn
                        @click="changeSubPageFID13630(i, i + 1)"
                        color="green"
                        class="mt-2 mp-2"
                        :disabled="i + 1 >= data.Recodes.FID13630.subReports.length"
                      >
                        ↓
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <v-btn
                      @click="subPageDel('FID13630')"
                      :disabled="allDisabled11969"
                      color="orange white--text"
                      class="ma-2"
                    >
                      画像削除
                    </v-btn>
                    <v-btn
                      @click="subPageAdd('FID13630')"
                      :disabled="allDisabled11969"
                      color="light-blue white--text"
                      class="ma-2"
                    >
                      画像追加
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template v-if="isShowInspection3">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11795"
                    class="divider11795"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11801"
                    class="plainText11801 text-body-1"
                    hide-details="auto"
                  >
                    3.マネジメントツール・人材育成＆教育
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea
                    label="テキスト"
                    v-model="data.Recodes.FID11797.value"
                    :disabled="allDisabled11969"
                    :background-color="inputColor(data.Recodes.FID11797)"
                    id="FID11797"
                    class="textfield11797"
                    outlined
                    rows="3"
                    auto-grow
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11815"
                    class="plainText11815 text-caption"
                    :class="classRequired(data.Recodes.FID11798)"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID11798.value"
                    :disabled="
                      data.Recodes.FID11798.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11798"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint11798"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID11798.value == '〇'
                          ? data.Recodes.FID11798.color
                          : data.Recodes.FID11798.disableColor
                      "
                      :disabled="data.Recodes.FID11798.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11798.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID11798.value == '×'
                          ? data.Recodes.FID11798.color
                          : data.Recodes.FID11798.disableColor
                      "
                      :disabled="data.Recodes.FID11798.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11798.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11816"
                    class="plainText11816 text-caption"
                    :class="classRequired(data.Recodes.FID11799)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID11799.value"
                    :disabled="
                      data.Recodes.FID11799.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11799"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint11799"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID11799.value == '〇'
                          ? data.Recodes.FID11799.color
                          : data.Recodes.FID11799.disableColor
                      "
                      :disabled="data.Recodes.FID11799.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11799.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID11799.value == '×'
                          ? data.Recodes.FID11799.color
                          : data.Recodes.FID11799.disableColor
                      "
                      :disabled="data.Recodes.FID11799.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11799.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="showImage3"
                    label="画像表示"
                    hide-details
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="showImage3">
                <v-row
                  v-if="
                    typeof data.Recodes.FID13710.subReports != 'undefined' &&
                    data.Recodes.FID13710.subReports.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    v-for="(report, i) in data.Recodes.FID13710.subReports"
                    :key="`[13710-${i}`"
                  >
                    <subPagePatrolUpload3
                      label=""
                      :disabled="allDisabled11969"
                      background-color="white"
                      :id="'FID13710-' + i"
                      class="subPage13710"
                      ref="subPage13710"
                      :Report="report"
                      :subindex="i"
                      :daleteReports.sync="data.DeleteSubReports"
                      v-on:clickSubmit="saveSubPage"
                      :myInit="subInit"
                      FID="FID13710"
                      hide-details="auto"
                    ></subPagePatrolUpload3>
                    <v-col align="right">
                      <v-btn
                        @click="changeSubPageFID13710(i, i + 1)"
                        color="green"
                        class="mt-2 mp-2"
                        :disabled="i + 1 >= data.Recodes.FID13710.subReports.length"
                      >
                        ↓
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <v-btn
                      @click="subPageDel('FID13710')"
                      :disabled="allDisabled11969"
                      color="orange white--text"
                      class="ma-2"
                    >
                      画像削除
                    </v-btn>
                    <v-btn
                      @click="subPageAdd('FID13710')"
                      :disabled="allDisabled11969"
                      color="light-blue white--text"
                      class="ma-2"
                    >
                      画像追加
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template v-if="isShowInspection4">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11800"
                    class="divider11800"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11806"
                    class="plainText11806 text-body-1"
                    hide-details="auto"
                  >
                    4.コンプライアンス（違法改造車/未認証での分解整備/不正な労働管理）
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea
                    label="テキスト"
                    v-model="data.Recodes.FID11802.value"
                    :disabled="allDisabled11969"
                    :background-color="inputColor(data.Recodes.FID11802)"
                    id="FID11802"
                    class="textfield11802"
                    outlined
                    rows="3"
                    auto-grow
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11817"
                    class="plainText11817 text-caption"
                    :class="classRequired(data.Recodes.FID11803)"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID11803.value"
                    :disabled="
                      data.Recodes.FID11803.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11803"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID11803.value == '〇'
                          ? data.Recodes.FID11803.color
                          : data.Recodes.FID11803.disableColor
                      "
                      :disabled="data.Recodes.FID11803.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11803.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID11803.value == '×'
                          ? data.Recodes.FID11803.color
                          : data.Recodes.FID11803.disableColor
                      "
                      :disabled="data.Recodes.FID11803.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11803.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11818"
                    class="plainText11818 text-caption"
                    :class="classRequired(data.Recodes.FID11804)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID11804.value"
                    :disabled="
                      data.Recodes.FID11804.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID11804"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint11804"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID11804.value == '〇'
                          ? data.Recodes.FID11804.color
                          : data.Recodes.FID11804.disableColor
                      "
                      :disabled="data.Recodes.FID11804.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11804.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID11804.value == '×'
                          ? data.Recodes.FID11804.color
                          : data.Recodes.FID11804.disableColor
                      "
                      :disabled="data.Recodes.FID11804.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID11804.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="showImage4"
                    label="画像表示"
                    hide-details
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="showImage4">
                <v-row
                  v-if="
                    typeof data.Recodes.FID13711.subReports != 'undefined' &&
                    data.Recodes.FID13711.subReports.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    v-for="(report, i) in data.Recodes.FID13711.subReports"
                    :key="`[13711-${i}`"
                  >
                    <subPagePatrolUpload4
                      label=""
                      :disabled="allDisabled11969"
                      background-color="white"
                      :id="'FID13711-' + i"
                      class="subPage13711"
                      ref="subPage13711"
                      :Report="report"
                      :subindex="i"
                      :daleteReports.sync="data.DeleteSubReports"
                      v-on:clickSubmit="saveSubPage"
                      :myInit="subInit"
                      FID="FID13711"
                      hide-details="auto"
                    ></subPagePatrolUpload4>
                    <v-col align="right">
                      <v-btn
                        @click="changeSubPageFID13711(i, i + 1)"
                        color="green"
                        class="mt-2 mp-2"
                        :disabled="i + 1 >= data.Recodes.FID13711.subReports.length"
                      >
                        ↓
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <v-btn
                      @click="subPageDel('FID13711')"
                      :disabled="allDisabled11969"
                      color="orange white--text"
                      class="ma-2"
                    >
                      画像削除
                    </v-btn>
                    <v-btn
                      @click="subPageAdd('FID13711')"
                      :disabled="allDisabled11969"
                      color="light-blue white--text"
                      class="ma-2"
                    >
                      画像追加
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template v-if="isShowInspection5">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12028"
                    class="divider12028"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12029"
                    class="plainText12029 text-body-1"
                    hide-details="auto"
                  >
                    5.実査（実現金/タイヤ・バッテリー在庫/黒本・施設点検）
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="auto"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12030"
                    class="plainText12030 text-body-1"
                    hide-details="auto"
                  >
                    実現金①
                  </div>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    @click="importMoneyCheck"
                    color="primary white--text"
                    :disabled="allDisabled11969"
                    >金銭点検チェックから値を取得
                  </v-btn>
                </v-col>
                <v-col
                  cols="auto"
                  class="align-self-center"
                  v-show="validMoney"
                >
                  <div
                    label=""
                    background-color="white"
                    id="FID12091"
                    class="plainText12091 text-body-1"
                    hide-details="auto"
                  >
                    <span style="color:red;">金銭点検チェックが行われていないため取得できません</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12031"
                    class="plainText12031 text-caption"
                    :class="classRequired(data.Recodes.FID12032)"
                    hide-details="auto"
                  >
                    現金差異
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12032.value"
                    :disabled="
                      data.Recodes.FID12032.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12032"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12032.value == '無'
                          ? data.Recodes.FID12032.color
                          : data.Recodes.FID12032.disableColor
                      "
                      :disabled="data.Recodes.FID12032.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12032.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12032.value == '有'
                          ? data.Recodes.FID12032.color
                          : data.Recodes.FID12032.disableColor
                      "
                      :disabled="data.Recodes.FID12032.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12032.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-dialog
                    v-model="data.Recodes.FID12033.modal"
                    :max-width="resizeWidth"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs">
                        <v-text-field
                          label="現金差異（円）"
                          v-model="data.Recodes.FID12033.value"
                          :disabled="allDisabled11969"
                          :rules="[
                            rules.required,
                            rules.smallNum,
                            rules.checkFID12033,
                          ]"
                          :background-color="inputColor(data.Recodes.FID12033)"
                          id="FID12033"
                          class="textfield12033"
                          append-icon="mdi-calculator"
                          @click:append="on.click"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </template>
                    <input-Number
                      v-if="data.Recodes.FID12033.modal"
                      title="現金差異（円）"
                      :value="data.Recodes.FID12033.value"
                      :rules="[rules.required, rules.smallNum]"
                      v-on:ok="
                        (data.Recodes.FID12033.modal = false),
                          $set(data.Recodes.FID12033, 'value', $event)
                      "
                      type="calculator"
                      AC
                    ></input-Number>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12034"
                    class="plainText12034 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12035.value"
                    :disabled="
                      data.Recodes.FID12035.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12035"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12035.value == '〇'
                          ? data.Recodes.FID12035.color
                          : data.Recodes.FID12035.disableColor
                      "
                      :disabled="data.Recodes.FID12035.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12035.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12035.value == '×'
                          ? data.Recodes.FID12035.color
                          : data.Recodes.FID12035.disableColor
                      "
                      :disabled="data.Recodes.FID12035.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12035.disabled }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12036"
                    class="plainText12036 text-caption"
                    :class="classRequired(data.Recodes.FID12037)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12037.value"
                    :disabled="
                      data.Recodes.FID12037.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12037"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12037"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12037.value == '〇'
                          ? data.Recodes.FID12037.color
                          : data.Recodes.FID12037.disableColor
                      "
                      :disabled="data.Recodes.FID12037.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12037.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12037.value == '×'
                          ? data.Recodes.FID12037.color
                          : data.Recodes.FID12037.disableColor
                      "
                      :disabled="data.Recodes.FID12037.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12037.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12039"
                    class="divider12039"
                    :style="'border-bottom-width: 1px !important;'"
                    color="#BDBDBD"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12040"
                    class="plainText12040 text-body-1"
                    hide-details="auto"
                  >
                    実現金②
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12044"
                    class="plainText12044 text-caption"
                    :class="classRequired(data.Recodes.FID12045)"
                    hide-details="auto"
                  >
                    釣銭機差異
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12045.value"
                    :disabled="
                      data.Recodes.FID12045.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12045"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12045.value == '無'
                          ? data.Recodes.FID12045.color
                          : data.Recodes.FID12045.disableColor
                      "
                      :disabled="data.Recodes.FID12045.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12045.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12045.value == '有'
                          ? data.Recodes.FID12045.color
                          : data.Recodes.FID12045.disableColor
                      "
                      :disabled="data.Recodes.FID12045.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12045.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-text-field
                    label="釣銭機ナンバー"
                    v-model="data.Recodes.FID12043.value"
                    :disabled="
                      data.Recodes.FID12043.disabled || allDisabled11969
                    "
                    :rules="[rules.checkFID12043]"
                    :background-color="inputColor(data.Recodes.FID12043)"
                    id="FID12043"
                    class="textfield12043"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-dialog
                    v-model="data.Recodes.FID12042.modal"
                    :max-width="resizeWidth"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs">
                        <v-text-field
                          label="釣銭機差異（円）"
                          v-model="data.Recodes.FID12042.value"
                          :disabled="
                            data.Recodes.FID12042.disabled || allDisabled11969
                          "
                          :rules="[
                            rules.required,
                            rules.smallNum,
                            rules.checkFID12042,
                          ]"
                          :background-color="inputColor(data.Recodes.FID12042)"
                          id="FID12042"
                          class="textfield12042"
                          append-icon="mdi-calculator"
                          @click:append="on.click"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </template>
                    <input-Number
                      v-if="data.Recodes.FID12042.modal"
                      title="釣銭機差異（円）"
                      :value="data.Recodes.FID12042.value"
                      :rules="[rules.required, rules.smallNum]"
                      v-on:ok="
                        (data.Recodes.FID12042.modal = false),
                          $set(data.Recodes.FID12042, 'value', $event)
                      "
                      type="calculator"
                      AC
                    ></input-Number>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12046"
                    class="plainText12046 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12047.value"
                    :disabled="
                      data.Recodes.FID12047.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12047"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12047.value == '〇'
                          ? data.Recodes.FID12047.color
                          : data.Recodes.FID12047.disableColor
                      "
                      :disabled="data.Recodes.FID12047.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12047.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12047.value == '×'
                          ? data.Recodes.FID12047.color
                          : data.Recodes.FID12047.disableColor
                      "
                      :disabled="data.Recodes.FID12047.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12047.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12047.value == '該当なし'
                          ? data.Recodes.FID12047.color
                          : data.Recodes.FID12047.disableColor
                      "
                      :disabled="NotApplicableAllDisabled"
                      :class="{ 'disabled-btn': NotApplicableAllDisabled }"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12048"
                    class="plainText12048 text-caption"
                    :class="classRequired(data.Recodes.FID12049)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12049.value"
                    :disabled="
                      data.Recodes.FID12049.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12049"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12049"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12049.value == '〇'
                          ? data.Recodes.FID12049.color
                          : data.Recodes.FID12049.disableColor
                      "
                      :disabled="data.Recodes.FID12049.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12049.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12049.value == '×'
                          ? data.Recodes.FID12049.color
                          : data.Recodes.FID12049.disableColor
                      "
                      :disabled="data.Recodes.FID12049.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12049.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12050"
                    class="divider12050"
                    :style="'border-bottom-width: 1px !important;'"
                    color="#BDBDBD"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12051"
                    class="plainText12051 text-body-1"
                    hide-details="auto"
                  >
                    実現金③
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2"></v-col>
                <v-col
                  cols="10"
                  sm="10"
                  md="10"
                  lg="10"
                  class="text-left align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12053"
                    class="plainText12053 text-body-1"
                    hide-details="auto"
                    style="color: red"
                  >
                    金銭点検表の全回収が未実施の日がある【SSログ（当月累計回数）】
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2">
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12054"
                    class=""
                    hide-details="auto"
                  ></div>
                </v-col>
                <v-col cols="6" sm="6" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12055.value"
                    :disabled="
                      data.Recodes.FID12055.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12055"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12055.value == '無'
                          ? data.Recodes.FID12055.color
                          : data.Recodes.FID12055.disableColor
                      "
                      :disabled="data.Recodes.FID12055.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12055.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12055.value == '有'
                          ? data.Recodes.FID12055.color
                          : data.Recodes.FID12055.disableColor
                      "
                      :disabled="data.Recodes.FID12055.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12055.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2"></v-col>
                <v-col
                  cols="10"
                  sm="10"
                  md="10"
                  lg="10"
                  class="text-left align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12056"
                    class="plainText12056 text-body-1"
                    hide-details="auto"
                    style="color: red"
                  >
                    釣銭機番号と使用用途を理由も無く変えている履歴がある【管理者ログ1（詳細）】
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2">
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12057"
                    class=""
                    hide-details="auto"
                  ></div>
                </v-col>
                <v-col cols="6" sm="6" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12058.value"
                    :disabled="
                      data.Recodes.FID12058.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12058"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12058.value == '無'
                          ? data.Recodes.FID12058.color
                          : data.Recodes.FID12058.disableColor
                      "
                      :disabled="data.Recodes.FID12058.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12058.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12058.value == '有'
                          ? data.Recodes.FID12058.color
                          : data.Recodes.FID12058.disableColor
                      "
                      :disabled="data.Recodes.FID12058.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12058.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2"></v-col>
                <v-col
                  cols="10"
                  sm="10"
                  md="10"
                  lg="10"
                  class="text-left align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12059"
                    class="plainText12059 text-body-1"
                    hide-details="auto"
                    style="color: red"
                  >
                    実施者が特定のスタッフに偏っている【SSログ（詳細）】
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2">
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12060"
                    class=""
                    hide-details="auto"
                  ></div>
                </v-col>
                <v-col cols="6" sm="6" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12061.value"
                    :disabled="
                      data.Recodes.FID12061.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12061"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12061.value == '無'
                          ? data.Recodes.FID12061.color
                          : data.Recodes.FID12061.disableColor
                      "
                      :disabled="data.Recodes.FID12061.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12061.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12061.value == '有'
                          ? data.Recodes.FID12061.color
                          : data.Recodes.FID12061.disableColor
                      "
                      :disabled="data.Recodes.FID12061.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12061.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12062"
                    class="plainText12062 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12063.value"
                    :disabled="
                      data.Recodes.FID12063.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12063"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12063.value == '〇'
                          ? data.Recodes.FID12063.color
                          : data.Recodes.FID12063.disableColor
                      "
                      :disabled="data.Recodes.FID12063.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12063.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12063.value == '×'
                          ? data.Recodes.FID12063.color
                          : data.Recodes.FID12063.disableColor
                      "
                      :disabled="data.Recodes.FID12063.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12063.disabled }"
                    >
                      ×
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12063.value == '該当なし'
                          ? data.Recodes.FID12063.color
                          : data.Recodes.FID12063.disableColor
                      "
                      :disabled="NotApplicableAllDisabled"
                      :class="{ 'disabled-btn': NotApplicableAllDisabled }"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12064"
                    class="plainText12064 text-caption"
                    :class="classRequired(data.Recodes.FID12065)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12065.value"
                    :disabled="
                      data.Recodes.FID12065.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12065"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12065"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12065.value == '〇'
                          ? data.Recodes.FID12065.color
                          : data.Recodes.FID12065.disableColor
                      "
                      :disabled="data.Recodes.FID12065.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12065.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12065.value == '×'
                          ? data.Recodes.FID12065.color
                          : data.Recodes.FID12065.disableColor
                      "
                      :disabled="data.Recodes.FID12065.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12065.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12066"
                    class="divider12066"
                    :style="'border-bottom-width: 1px !important;'"
                    color="#BDBDBD"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12067"
                    class="plainText12067 text-body-1"
                    hide-details="auto"
                  >
                    実現金④
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12069"
                    class="plainText12069 text-caption"
                    :class="classRequired(data.Recodes.FID12070)"
                    hide-details="auto"
                  >
                    先売上
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12070.value"
                    :disabled="
                      data.Recodes.FID12070.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12070"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12070.value == '無'
                          ? data.Recodes.FID12070.color
                          : data.Recodes.FID12070.disableColor
                      "
                      :disabled="data.Recodes.FID12070.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12070.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12070.value == '有'
                          ? data.Recodes.FID12070.color
                          : data.Recodes.FID12070.disableColor
                      "
                      :disabled="data.Recodes.FID12070.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12070.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-text-field
                    label="商品名"
                    v-model="data.Recodes.FID12075.value"
                    :disabled="
                      data.Recodes.FID12075.disabled || allDisabled11969
                    "
                    :rules="[rules.checkFID12075]"
                    :background-color="inputColor(data.Recodes.FID12075)"
                    id="FID12075"
                    class="textfield12075"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-dialog
                    v-model="data.Recodes.FID12076.modal"
                    :max-width="resizeWidth"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs">
                        <v-text-field
                          label="金額"
                          v-model="data.Recodes.FID12076.value"
                          :disabled="
                            data.Recodes.FID12076.disabled || allDisabled11969
                          "
                          :rules="[
                            rules.required,
                            rules.smallNum,
                            rules.checkFID12076,
                          ]"
                          :background-color="inputColor(data.Recodes.FID12076)"
                          id="FID12076"
                          class="textfield12076"
                          append-icon="mdi-calculator"
                          @click:append="on.click"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </template>
                    <input-Number
                      v-if="data.Recodes.FID12076.modal"
                      title="金額"
                      :value="data.Recodes.FID12076.value"
                      :rules="[rules.required, rules.smallNum]"
                      v-on:ok="
                        (data.Recodes.FID12076.modal = false),
                          $set(data.Recodes.FID12076, 'value', $event)
                      "
                      type="calculator"
                      AC
                    ></input-Number>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12071"
                    class="plainText12071 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12072.value"
                    :disabled="
                      data.Recodes.FID12072.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12072"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12072.value == '〇'
                          ? data.Recodes.FID12072.color
                          : data.Recodes.FID12072.disableColor
                      "
                      :disabled="data.Recodes.FID12072.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12072.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12072.value == '×'
                          ? data.Recodes.FID12072.color
                          : data.Recodes.FID12072.disableColor
                      "
                      :disabled="data.Recodes.FID12072.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12072.disabled }"
                    >
                      ×
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12072.value == '該当なし'
                          ? data.Recodes.FID12072.color
                          : data.Recodes.FID12072.disableColor
                      "
                      :disabled="NotApplicableAllDisabled"
                      :class="{ 'disabled-btn': NotApplicableAllDisabled }"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12073"
                    class="plainText12073 text-caption"
                    :class="classRequired(data.Recodes.FID12074)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12074.value"
                    :disabled="
                      data.Recodes.FID12074.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12074"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12074"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12074.value == '〇'
                          ? data.Recodes.FID12074.color
                          : data.Recodes.FID12074.disableColor
                      "
                      :disabled="data.Recodes.FID12074.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12074.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12074.value == '×'
                          ? data.Recodes.FID12074.color
                          : data.Recodes.FID12074.disableColor
                      "
                      :disabled="data.Recodes.FID12074.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12074.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12077"
                    class="divider12077"
                    :style="'border-bottom: 2px dotted #BDBDBD !important;'"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12078"
                    class="plainText12078 text-caption"
                    :class="classRequired(data.Recodes.FID12079)"
                    hide-details="auto"
                  >
                    規定外保管物
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12079.value"
                    :disabled="
                      data.Recodes.FID12079.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12079"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12079.value == '無'
                          ? data.Recodes.FID12079.color
                          : data.Recodes.FID12079.disableColor
                      "
                      :disabled="data.Recodes.FID12079.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12079.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12079.value == '有'
                          ? data.Recodes.FID12079.color
                          : data.Recodes.FID12079.disableColor
                      "
                      :disabled="data.Recodes.FID12079.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12079.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-text-field
                    label="名称"
                    v-model="data.Recodes.FID12080.value"
                    :disabled="allDisabled11969"
                    :background-color="inputColor(data.Recodes.FID12080)"
                    id="FID12080"
                    class="textfield12080"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12081"
                    class="plainText12081 text-caption"
                    :class="classRequired(data.Recodes.FID12082)"
                    hide-details="auto"
                  >
                    その他金券類
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12082.value"
                    :disabled="
                      data.Recodes.FID12082.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12082"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12082.value == '無'
                          ? data.Recodes.FID12082.color
                          : data.Recodes.FID12082.disableColor
                      "
                      :disabled="data.Recodes.FID12082.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12082.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12082.value == '有'
                          ? data.Recodes.FID12082.color
                          : data.Recodes.FID12082.disableColor
                      "
                      :disabled="data.Recodes.FID12082.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12082.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12083"
                    class="plainText12083 text-caption"
                    hide-details="auto"
                  >
                    受払表作成
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12084.value"
                    :disabled="
                      data.Recodes.FID12084.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12084"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12084.value == '〇'
                          ? data.Recodes.FID12084.color
                          : data.Recodes.FID12084.disableColor
                      "
                      :disabled="data.Recodes.FID12084.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12084.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12084.value == '×'
                          ? data.Recodes.FID12084.color
                          : data.Recodes.FID12084.disableColor
                      "
                      :disabled="data.Recodes.FID12084.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12084.disabled }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12085"
                    class="plainText12085 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12086.value"
                    :disabled="
                      data.Recodes.FID12086.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12086"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12086.value == '〇'
                          ? data.Recodes.FID12086.color
                          : data.Recodes.FID12086.disableColor
                      "
                      :disabled="data.Recodes.FID12086.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12086.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12086.value == '×'
                          ? data.Recodes.FID12086.color
                          : data.Recodes.FID12086.disableColor
                      "
                      :disabled="data.Recodes.FID12086.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12086.disabled }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12087"
                    class="plainText12087 text-caption"
                    :class="classRequired(data.Recodes.FID12088)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12088.value"
                    :disabled="
                      data.Recodes.FID12088.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    :background-color="inputColor(data.Recodes.FID12088)"
                    id="FID12088"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12088"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12088.value == '〇'
                          ? data.Recodes.FID12088.color
                          : data.Recodes.FID12088.disableColor
                      "
                      :disabled="data.Recodes.FID12088.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12088.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12088.value == '×'
                          ? data.Recodes.FID12088.color
                          : data.Recodes.FID12088.disableColor
                      "
                      :disabled="data.Recodes.FID12088.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12088.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12090"
                    class="divider12090"
                    :style="'border-bottom-width: 1px !important;'"
                    color="#BDBDBD"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="auto"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12091"
                    class="plainText12091 text-body-1"
                    hide-details="auto"
                  >
                    在庫⑤
                  </div>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    @click="importStockCheck"
                    color="green white--text"
                    :disabled="allDisabled11969"
                    >在庫差異チェックから値を取得
                  </v-btn>
                </v-col>
                <v-col
                  cols="auto"
                  class="align-self-center"
                  v-show="validStock"
                >
                  <div
                    label=""
                    background-color="white"
                    id="FID12091"
                    class="plainText12091 text-body-1"
                    hide-details="auto"
                  >
                    <span style="color:red;">在庫差異チェックが行われていないため取得できません</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12093"
                    class="plainText12093 text-caption"
                    :class="classRequired(data.Recodes.FID12094)"
                    hide-details="auto"
                  >
                    タイヤ差異
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2" class="align-self-center">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12094.value"
                    :disabled="
                      data.Recodes.FID12094.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12094"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12094.value == '無'
                          ? data.Recodes.FID12094.color
                          : data.Recodes.FID12094.disableColor
                      "
                      :disabled="data.Recodes.FID12094.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12094.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12094.value == '有'
                          ? data.Recodes.FID12094.color
                          : data.Recodes.FID12094.disableColor
                      "
                      :disabled="data.Recodes.FID12094.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12094.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-dialog
                    v-model="data.Recodes.FID12095.modal"
                    :max-width="resizeWidth"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs">
                        <v-text-field
                          label="差異本数"
                          v-model="data.Recodes.FID12095.value"
                          :disabled="
                            data.Recodes.FID12095.disabled || allDisabled11969
                          "
                          :rules="[rules.smallNum, rules.checkFID12095]"
                          :background-color="inputColor(data.Recodes.FID12095)"
                          id="FID12095"
                          class="textfield12095"
                          append-icon="mdi-calculator"
                          @click:append="on.click"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </template>
                    <input-Number
                      v-if="data.Recodes.FID12095.modal"
                      title="差異本数"
                      :value="data.Recodes.FID12095.value"
                      :rules="[rules.smallNum]"
                      v-on:ok="
                        (data.Recodes.FID12095.modal = false),
                          $set(data.Recodes.FID12095, 'value', $event)
                      "
                      type="calculator"
                      AC
                    ></input-Number>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12096"
                    class="plainText12096 text-caption"
                    :class="classRequired(data.Recodes.FID12097)"
                    hide-details="auto"
                  >
                    簿外在庫
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12097.value"
                    :disabled="
                      data.Recodes.FID12097.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12097"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12097.value == '無'
                          ? data.Recodes.FID12097.color
                          : data.Recodes.FID12097.disableColor
                      "
                      :disabled="data.Recodes.FID12097.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12097.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12097.value == '有'
                          ? data.Recodes.FID12097.color
                          : data.Recodes.FID12097.disableColor
                      "
                      :disabled="data.Recodes.FID12097.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12097.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12098"
                    class="plainText12098 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12099.value"
                    :disabled="
                      data.Recodes.FID12099.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12099"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12099.value == '〇'
                          ? data.Recodes.FID12099.color
                          : data.Recodes.FID12099.disableColor
                      "
                      :disabled="data.Recodes.FID12099.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12099.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12099.value == '×'
                          ? data.Recodes.FID12099.color
                          : data.Recodes.FID12099.disableColor
                      "
                      :disabled="data.Recodes.FID12099.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12099.disabled }"
                    >
                      ×
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12099.value == '該当なし'
                          ? data.Recodes.FID12099.color
                          : data.Recodes.FID12099.disableColor
                      "
                      :disabled="NotApplicableAllDisabled"
                      :class="{ 'disabled-btn': NotApplicableAllDisabled }"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12100"
                    class="plainText12100 text-caption"
                    :class="classRequired(data.Recodes.FID12101)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12101.value"
                    :disabled="
                      data.Recodes.FID12101.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12101"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12101"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12101.value == '〇'
                          ? data.Recodes.FID12101.color
                          : data.Recodes.FID12101.disableColor
                      "
                      :disabled="data.Recodes.FID12101.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12101.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12101.value == '×'
                          ? data.Recodes.FID12101.color
                          : data.Recodes.FID12101.disableColor
                      "
                      :disabled="data.Recodes.FID12101.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12101.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12105"
                    class="divider12105"
                    :style="'border-bottom: 2px dotted #BDBDBD !important;'"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12107"
                    class="plainText12107 text-caption"
                    :class="classRequired(data.Recodes.FID12108)"
                    hide-details="auto"
                  >
                    バッテリー差異
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2" class="align-self-center">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12108.value"
                    :disabled="
                      data.Recodes.FID12108.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12108"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12108.value == '無'
                          ? data.Recodes.FID12108.color
                          : data.Recodes.FID12108.disableColor
                      "
                      :disabled="data.Recodes.FID12108.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12108.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12108.value == '有'
                          ? data.Recodes.FID12108.color
                          : data.Recodes.FID12108.disableColor
                      "
                      :disabled="data.Recodes.FID12108.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12108.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-dialog
                    v-model="data.Recodes.FID12109.modal"
                    :max-width="resizeWidth"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs">
                        <v-text-field
                          label="差異個数"
                          v-model="data.Recodes.FID12109.value"
                          :disabled="
                            data.Recodes.FID12109.disabled || allDisabled11969
                          "
                          :rules="[rules.smallNum, rules.checkFID12109]"
                          :background-color="inputColor(data.Recodes.FID12109)"
                          id="FID12109"
                          class="textfield12109"
                          append-icon="mdi-calculator"
                          @click:append="on.click"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </template>
                    <input-Number
                      v-if="data.Recodes.FID12109.modal"
                      title="差異個数"
                      :value="data.Recodes.FID12109.value"
                      :rules="[rules.smallNum]"
                      v-on:ok="
                        (data.Recodes.FID12109.modal = false),
                          $set(data.Recodes.FID12109, 'value', $event)
                      "
                      type="calculator"
                      AC
                    ></input-Number>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12110"
                    class="plainText12110 text-caption"
                    :class="classRequired(data.Recodes.FID12111)"
                    hide-details="auto"
                  >
                    簿外在庫
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12111.value"
                    :disabled="
                      data.Recodes.FID12111.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12111"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12111.value == '無'
                          ? data.Recodes.FID12111.color
                          : data.Recodes.FID12111.disableColor
                      "
                      :disabled="data.Recodes.FID12111.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12111.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12111.value == '有'
                          ? data.Recodes.FID12111.color
                          : data.Recodes.FID12111.disableColor
                      "
                      :disabled="data.Recodes.FID12111.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12111.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12112"
                    class="plainText12112 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12113.value"
                    :disabled="
                      data.Recodes.FID12113.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12113"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12113.value == '〇'
                          ? data.Recodes.FID12113.color
                          : data.Recodes.FID12113.disableColor
                      "
                      :disabled="data.Recodes.FID12113.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12113.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12113.value == '×'
                          ? data.Recodes.FID12113.color
                          : data.Recodes.FID12113.disableColor
                      "
                      :disabled="data.Recodes.FID12113.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12113.disabled }"
                    >
                      ×
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12113.value == '該当なし'
                          ? data.Recodes.FID12113.color
                          : data.Recodes.FID12113.disableColor
                      "
                      :disabled="NotApplicableAllDisabled"
                      :class="{ 'disabled-btn': NotApplicableAllDisabled }"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12114"
                    class="plainText12114 text-caption"
                    :class="classRequired(data.Recodes.FID12115)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12115.value"
                    :disabled="
                      data.Recodes.FID12115.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12115"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12115"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12115.value == '〇'
                          ? data.Recodes.FID12115.color
                          : data.Recodes.FID12115.disableColor
                      "
                      :disabled="data.Recodes.FID12115.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12115.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12115.value == '×'
                          ? data.Recodes.FID12115.color
                          : data.Recodes.FID12115.disableColor
                      "
                      :disabled="data.Recodes.FID12115.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12115.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12116"
                    class="divider12116"
                    :style="'border-bottom-width: 1px !important;'"
                    color="#BDBDBD"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12117"
                    class="plainText12117 text-body-1"
                    hide-details="auto"
                  >
                    施設点検⑥
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12119"
                    class="plainText12119 text-caption"
                    :class="classRequired(data.Recodes.FID12120)"
                    hide-details="auto"
                  >
                    計量機内の点検（油漏れ）
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12120.value"
                    :disabled="
                      data.Recodes.FID12120.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12120"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12120.value == 'OK'
                          ? data.Recodes.FID12120.color
                          : data.Recodes.FID12120.disableColor
                      "
                      :disabled="data.Recodes.FID12120.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12120.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12120.value == 'NG'
                          ? data.Recodes.FID12120.color
                          : data.Recodes.FID12120.disableColor
                      "
                      :disabled="data.Recodes.FID12120.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12120.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12121"
                    class="plainText12121 text-caption"
                    :class="classRequired(data.Recodes.FID12122)"
                    hide-details="auto"
                  >
                    油水分離槽清掃状況
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12122.value"
                    :disabled="
                      data.Recodes.FID12122.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12122"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12122.value == 'OK'
                          ? data.Recodes.FID12122.color
                          : data.Recodes.FID12122.disableColor
                      "
                      :disabled="data.Recodes.FID12122.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12122.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12122.value == 'NG'
                          ? data.Recodes.FID12122.color
                          : data.Recodes.FID12122.disableColor
                      "
                      :disabled="data.Recodes.FID12122.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12122.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12123"
                    class="plainText12123 text-caption"
                    :class="classRequired(data.Recodes.FID12124)"
                    hide-details="auto"
                  >
                    マンホール内の滞水
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12124.value"
                    :disabled="
                      data.Recodes.FID12124.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12124"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12124.value == 'OK'
                          ? data.Recodes.FID12124.color
                          : data.Recodes.FID12124.disableColor
                      "
                      :disabled="data.Recodes.FID12124.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12124.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12124.value == 'NG'
                          ? data.Recodes.FID12124.color
                          : data.Recodes.FID12124.disableColor
                      "
                      :disabled="data.Recodes.FID12124.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12124.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12125"
                    class="plainText12125 text-caption"
                    :class="classRequired(data.Recodes.FID12126)"
                    hide-details="auto"
                  >
                    消火器の期限・破損
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12126.value"
                    :disabled="
                      data.Recodes.FID12126.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12126"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12126.value == 'OK'
                          ? data.Recodes.FID12126.color
                          : data.Recodes.FID12126.disableColor
                      "
                      :disabled="data.Recodes.FID12126.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12126.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12126.value == 'NG'
                          ? data.Recodes.FID12126.color
                          : data.Recodes.FID12126.disableColor
                      "
                      :disabled="data.Recodes.FID12126.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12126.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12127"
                    class="plainText12127 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12128.value"
                    :disabled="
                      data.Recodes.FID12128.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12128"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12128.value == '〇'
                          ? data.Recodes.FID12128.color
                          : data.Recodes.FID12128.disableColor
                      "
                      :disabled="data.Recodes.FID12128.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12128.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12128.value == '×'
                          ? data.Recodes.FID12128.color
                          : data.Recodes.FID12128.disableColor
                      "
                      :disabled="data.Recodes.FID12128.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12128.disabled }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12129"
                    class="plainText12129 text-caption"
                    :class="classRequired(data.Recodes.FID12130)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12130.value"
                    :disabled="
                      data.Recodes.FID12130.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12130"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12130"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12130.value == '〇'
                          ? data.Recodes.FID12130.color
                          : data.Recodes.FID12130.disableColor
                      "
                      :disabled="data.Recodes.FID12130.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12130.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12130.value == '×'
                          ? data.Recodes.FID12130.color
                          : data.Recodes.FID12130.disableColor
                      "
                      :disabled="data.Recodes.FID12130.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12130.disabled }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="showImage5"
                    label="画像表示"
                    hide-details
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="showImage5">
                <v-row
                  v-if="
                    typeof data.Recodes.FID13712.subReports != 'undefined' &&
                    data.Recodes.FID13712.subReports.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    v-for="(report, i) in data.Recodes.FID13712.subReports"
                    :key="`[13712-${i}`"
                  >
                    <subPagePatrolUpload5
                      label=""
                      :disabled="allDisabled11969"
                      background-color="white"
                      :id="'FID13712-' + i"
                      class="subPage13712"
                      ref="subPage13712"
                      :Report="report"
                      :subindex="i"
                      :daleteReports.sync="data.DeleteSubReports"
                      v-on:clickSubmit="saveSubPage"
                      :myInit="subInit"
                      FID="FID13712"
                      hide-details="auto"
                    ></subPagePatrolUpload5>
                    <v-col align="right">
                      <v-btn
                        @click="changeSubPageFID13712(i, i + 1)"
                        color="green"
                        class="mt-2 mp-2"
                        :disabled="i + 1 >= data.Recodes.FID13712.subReports.length"
                      >
                        ↓
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <v-btn
                      @click="subPageDel('FID13712')"
                      :disabled="allDisabled11969"
                      color="orange white--text"
                      class="ma-2"
                    >
                      画像削除
                    </v-btn>
                    <v-btn
                      @click="subPageAdd('FID13712')"
                      :disabled="allDisabled11969"
                      color="light-blue white--text"
                      class="ma-2"
                    >
                      画像追加
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template v-if="isShowInspection6">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12131"
                    class="divider12131"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12132"
                    class="plainText12132 text-body-1"
                    hide-details="auto"
                  >
                    6.SS併設認証【認証併設SSのみ】<span style="color:red;">＊該当なしの場合は「該当なし」を選択</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12134"
                    class="plainText12134 text-body-1"
                    hide-details="auto"
                  >
                    分解整備⑦
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12135"
                    class="plainText12135 text-caption"
                    :class="classRequired(data.Recodes.FID12136)"
                    hide-details="auto"
                  >
                    分解整備料金表
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12136.value"
                    :disabled="
                      data.Recodes.FID12136.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12136"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12136.value == 'OK'
                          ? data.Recodes.FID12136.color
                          : data.Recodes.FID12136.disableColor
                      "
                      :disabled="data.Recodes.FID12136.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12136.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12136.value == 'NG'
                          ? data.Recodes.FID12136.color
                          : data.Recodes.FID12136.disableColor
                      "
                      :disabled="data.Recodes.FID12136.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12136.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12137"
                    class="plainText12137 text-caption"
                    :class="classRequired(data.Recodes.FID12138)"
                    hide-details="auto"
                  >
                    分解整備工具
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12138.value"
                    :disabled="
                      data.Recodes.FID12138.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12138"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12138.value == 'OK'
                          ? data.Recodes.FID12138.color
                          : data.Recodes.FID12138.disableColor
                      "
                      :disabled="data.Recodes.FID12138.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12138.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12138.value == 'NG'
                          ? data.Recodes.FID12138.color
                          : data.Recodes.FID12138.disableColor
                      "
                      :disabled="data.Recodes.FID12138.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12138.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12139"
                    class="plainText12139 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12140.value"
                    :disabled="
                      data.Recodes.FID12140.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12140"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12140.value == '〇'
                          ? data.Recodes.FID12140.color
                          : data.Recodes.FID12140.disableColor
                      "
                      :disabled="data.Recodes.FID12140.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12140.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12140.value == '×'
                          ? data.Recodes.FID12140.color
                          : data.Recodes.FID12140.disableColor
                      "
                      :disabled="data.Recodes.FID12140.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12140.disabled }"
                    >
                      ×
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12140.value == '該当なし'
                          ? data.Recodes.FID12140.color
                          : data.Recodes.FID12140.disableColor
                      "
                      :disabled="NotApplicableAllDisabled || allDisabled11969"
                      :class="{ 'disabled-btn': NotApplicableAllDisabled || allDisabled11969 }"
                      @click="onBtn12140()"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12141"
                    class="plainText12141 text-caption"
                    :class="classRequired(data.Recodes.FID12142)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12142.value"
                    :disabled="
                      data.Recodes.FID12142.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12142"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12142"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12142.value == '〇'
                          ? data.Recodes.FID12142.color
                          : data.Recodes.FID12142.disableColor
                      "
                      :disabled="data.Recodes.FID12142.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12142.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12142.value == '×'
                          ? data.Recodes.FID12142.color
                          : data.Recodes.FID12142.disableColor
                      "
                      :disabled="data.Recodes.FID12142.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12142.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12145"
                    class="divider12145"
                    :style="'border-bottom-width: 1px !important;'"
                    color="#BDBDBD"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12146"
                    class="plainText12146 text-body-1"
                    hide-details="auto"
                  >
                    分解整備⑧
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5" sm="5" md="4" lg="4">
                  <v-dialog
                    v-model="data.Recodes.FID12148.modal"
                    :max-width="resizeWidth"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs">
                        <v-text-field
                          label="分解記録簿件数（件）"
                          v-model="data.Recodes.FID12148.value"
                          :disabled="
                            data.Recodes.FID12148.disabled || allDisabled11969
                          "
                          :rules="[rules.smallNum, rules.checkFID12148]"
                          :background-color="inputColor(data.Recodes.FID12148)"
                          id="FID12148"
                          class="textfield12148"
                          append-icon="mdi-calculator"
                          @click:append="on.click"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </template>
                    <input-Number
                      v-if="data.Recodes.FID12148.modal"
                      title="分解記録簿件数（件）"
                      :value="data.Recodes.FID12148.value"
                      :rules="[rules.smallNum]"
                      v-on:ok="
                        (data.Recodes.FID12148.modal = false),
                          $set(data.Recodes.FID12148, 'value', $event)
                      "
                      type="calculator"
                      AC
                    ></input-Number>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="1"
                  lg="1"
                  class="text-center align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12149"
                    class="plainText12149 text-body-1"
                    hide-details="auto"
                  >
                    中
                  </div>
                </v-col>
                <v-col cols="5" sm="5" md="4" lg="4">
                  <v-dialog
                    v-model="data.Recodes.FID12150.modal"
                    :max-width="resizeWidth"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs">
                        <v-text-field
                          label="分解記録簿件数（件）"
                          v-model="data.Recodes.FID12150.value"
                          :disabled="
                            data.Recodes.FID12150.disabled || allDisabled11969
                          "
                          :rules="[rules.smallNum, rules.checkFID12150]"
                          :background-color="inputColor(data.Recodes.FID12150)"
                          id="FID12150"
                          class="textfield12150"
                          append-icon="mdi-calculator"
                          @click:append="on.click"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </template>
                    <input-Number
                      v-if="data.Recodes.FID12150.modal"
                      title="分解記録簿件数（件）"
                      :value="data.Recodes.FID12150.value"
                      :rules="[rules.smallNum]"
                      v-on:ok="
                        (data.Recodes.FID12150.modal = false),
                          $set(data.Recodes.FID12150, 'value', $event)
                      "
                      type="calculator"
                      AC
                    ></input-Number>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12151"
                    class="plainText12151 text-caption"
                    :class="classRequired(data.Recodes.FID12152)"
                    hide-details="auto"
                  >
                    分解整備記録簿記載
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12152.value"
                    :disabled="
                      data.Recodes.FID12152.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12152"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12152.value == 'OK'
                          ? data.Recodes.FID12152.color
                          : data.Recodes.FID12152.disableColor
                      "
                      :disabled="data.Recodes.FID12152.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12152.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12152.value == 'NG'
                          ? data.Recodes.FID12152.color
                          : data.Recodes.FID12152.disableColor
                      "
                      :disabled="data.Recodes.FID12152.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12152.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12153"
                    class="plainText12153 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12154.value"
                    :disabled="
                      data.Recodes.FID12154.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12154"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12154.value == '〇'
                          ? data.Recodes.FID12154.color
                          : data.Recodes.FID12154.disableColor
                      "
                      :disabled="data.Recodes.FID12154.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12154.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12154.value == '×'
                          ? data.Recodes.FID12154.color
                          : data.Recodes.FID12154.disableColor
                      "
                      :disabled="data.Recodes.FID12154.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12154.disabled }"
                    >
                      ×
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12154.value == '該当なし'
                          ? data.Recodes.FID12154.color
                          : data.Recodes.FID12154.disableColor
                      "
                      :disabled="NotApplicableAllDisabled || allDisabled11969"
                      :class="{ 'disabled-btn': NotApplicableAllDisabled || allDisabled11969 }"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12155"
                    class="plainText12155 text-caption"
                    :class="classRequired(data.Recodes.FID12156)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12156.value"
                    :disabled="
                      data.Recodes.FID12156.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12156"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12156"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12156.value == '〇'
                          ? data.Recodes.FID12156.color
                          : data.Recodes.FID12156.disableColor
                      "
                      :disabled="data.Recodes.FID12156.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12156.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12156.value == '×'
                          ? data.Recodes.FID12156.color
                          : data.Recodes.FID12156.disableColor
                      "
                      :disabled="data.Recodes.FID12156.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12156.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12157"
                    class="divider12157"
                    :style="'border-bottom-width: 1px !important;'"
                    color="#BDBDBD"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12158"
                    class="plainText12158 text-body-1"
                    hide-details="auto"
                  >
                    分解整備⑨
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12160"
                    class="plainText12160 text-caption"
                    :class="classRequired(data.Recodes.FID12161)"
                    hide-details="auto"
                  >
                    ステッカー番号記載
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12161.value"
                    :disabled="
                      data.Recodes.FID12161.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12161"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12161.value == 'OK'
                          ? data.Recodes.FID12161.color
                          : data.Recodes.FID12161.disableColor
                      "
                      :disabled="data.Recodes.FID12161.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12161.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12161.value == 'NG'
                          ? data.Recodes.FID12161.color
                          : data.Recodes.FID12161.disableColor
                      "
                      :disabled="data.Recodes.FID12161.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12161.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12162"
                    class="plainText12162 text-caption"
                    :class="classRequired(data.Recodes.FID12163)"
                    hide-details="auto"
                  >
                    自署
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12163.value"
                    :disabled="
                      data.Recodes.FID12163.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12163"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12163.value == 'OK'
                          ? data.Recodes.FID12163.color
                          : data.Recodes.FID12163.disableColor
                      "
                      :disabled="data.Recodes.FID12163.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12163.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12163.value == 'NG'
                          ? data.Recodes.FID12163.color
                          : data.Recodes.FID12163.disableColor
                      "
                      :disabled="data.Recodes.FID12163.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12163.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12164"
                    class="plainText12164 text-caption"
                    :class="classRequired(data.Recodes.FID12165)"
                    hide-details="auto"
                  >
                    シフト出勤状況
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12165.value"
                    :disabled="
                      data.Recodes.FID12165.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12165"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12165.value == 'OK'
                          ? data.Recodes.FID12165.color
                          : data.Recodes.FID12165.disableColor
                      "
                      :disabled="data.Recodes.FID12165.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12165.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12165.value == 'NG'
                          ? data.Recodes.FID12165.color
                          : data.Recodes.FID12165.disableColor
                      "
                      :disabled="data.Recodes.FID12165.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12165.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12166"
                    class="plainText12166 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12167.value"
                    :disabled="
                      data.Recodes.FID12167.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12167"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12167.value == '〇'
                          ? data.Recodes.FID12167.color
                          : data.Recodes.FID12167.disableColor
                      "
                      :disabled="data.Recodes.FID12167.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12167.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12167.value == '×'
                          ? data.Recodes.FID12167.color
                          : data.Recodes.FID12167.disableColor
                      "
                      :disabled="data.Recodes.FID12167.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12167.disabled }"
                    >
                      ×
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12167.value == '該当なし'
                          ? data.Recodes.FID12167.color
                          : data.Recodes.FID12167.disableColor
                      "
                      :disabled="NotApplicableAllDisabled || allDisabled11969"
                      :class="{ 'disabled-btn': NotApplicableAllDisabled || allDisabled11969 }"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12168"
                    class="plainText12168 text-caption"
                    :class="classRequired(data.Recodes.FID12169)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12169.value"
                    :disabled="
                      data.Recodes.FID12169.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12169"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12169"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12169.value == '〇'
                          ? data.Recodes.FID12169.color
                          : data.Recodes.FID12169.disableColor
                      "
                      :disabled="data.Recodes.FID12169.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12169.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12169.value == '×'
                          ? data.Recodes.FID12169.color
                          : data.Recodes.FID12169.disableColor
                      "
                      :disabled="data.Recodes.FID12169.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12169.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="showImage6"
                    label="画像表示"
                    hide-details
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="showImage6">
                <v-row
                  v-if="
                    typeof data.Recodes.FID13713.subReports != 'undefined' &&
                    data.Recodes.FID13713.subReports.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    v-for="(report, i) in data.Recodes.FID13713.subReports"
                    :key="`[13713-${i}`"
                  >
                    <subPagePatrolUpload6
                      label=""
                      :disabled="allDisabled11969"
                      background-color="white"
                      :id="'FID13713-' + i"
                      class="subPage13713"
                      ref="subPage13713"
                      :Report="report"
                      :subindex="i"
                      :daleteReports.sync="data.DeleteSubReports"
                      v-on:clickSubmit="saveSubPage"
                      :myInit="subInit"
                      FID="FID13713"
                      hide-details="auto"
                    ></subPagePatrolUpload6>
                    <v-col align="right">
                      <v-btn
                        @click="changeSubPageFID13713(i, i + 1)"
                        color="green"
                        class="mt-2 mp-2"
                        :disabled="i + 1 >= data.Recodes.FID13713.subReports.length"
                      >
                        ↓
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <v-btn
                      @click="subPageDel('FID13713')"
                      :disabled="allDisabled11969"
                      color="orange white--text"
                      class="ma-2"
                    >
                      画像削除
                    </v-btn>
                    <v-btn
                      @click="subPageAdd('FID13713')"
                      :disabled="allDisabled11969"
                      color="light-blue white--text"
                      class="ma-2"
                    >
                      画像追加
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template v-if="isShowInspection7">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12170"
                    class="divider12170"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12171"
                    class="plainText12171 text-body-1"
                    hide-details="auto"
                    style="color: red"
                  >
                    7.「SS管理チェック」実施状況の確認
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12173"
                    class="plainText12173 text-body-1"
                    hide-details="auto"
                    style="color: red"
                  >
                    SS管理チェック
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12174"
                    class="plainText12174 text-caption"
                    :class="classRequired(data.Recodes.FID12175)"
                    hide-details="auto"
                  >
                    シート運用
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12175.value"
                    :disabled="
                      data.Recodes.FID12175.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12175"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12175.value == 'OK'
                          ? data.Recodes.FID12175.color
                          : data.Recodes.FID12175.disableColor
                      "
                      :disabled="data.Recodes.FID12175.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12175.disabled || allDisabled11969 }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12175.value == 'NG'
                          ? data.Recodes.FID12175.color
                          : data.Recodes.FID12175.disableColor
                      "
                      :disabled="data.Recodes.FID12175.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12175.disabled || allDisabled11969 }"
                    >
                      NG
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12176"
                    class="plainText12176 text-caption"
                    :class="classRequired(data.Recodes.FID12177)"
                    hide-details="auto"
                  >
                    不備項目
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12177.value"
                    :disabled="
                      data.Recodes.FID12177.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12177"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="無"
                      :color="
                        data.Recodes.FID12177.value == '無'
                          ? data.Recodes.FID12177.color
                          : data.Recodes.FID12177.disableColor
                      "
                      :disabled="data.Recodes.FID12177.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12177.disabled || allDisabled11969 }"
                    >
                      無
                    </v-btn>
                    <v-btn
                      value="有"
                      :color="
                        data.Recodes.FID12177.value == '有'
                          ? data.Recodes.FID12177.color
                          : data.Recodes.FID12177.disableColor
                      "
                      :disabled="data.Recodes.FID12177.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12177.disabled || allDisabled11969 }"
                    >
                      有
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12178"
                    class="plainText12178 text-caption"
                    :class="classRequired(data.Recodes.FID12179)"
                    hide-details="auto"
                  >
                    再発防止策の運用
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                  <v-btn-toggle
                    label=""
                    v-model="data.Recodes.FID12179.value"
                    :disabled="
                      data.Recodes.FID12179.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12179"
                    class="radio-group"
                    hide-details="auto"
                    hint="※不備項目が有の場合には、再発防止策の運用はOKかNGを選択してください"
                    persistent-hint
                  >
                    <v-btn
                      value="OK"
                      :color="
                        data.Recodes.FID12179.value == 'OK'
                          ? data.Recodes.FID12179.color
                          : data.Recodes.FID12179.disableColor
                      "
                      :disabled="data.Recodes.FID12179.disabled || allDisabled11969 || disabledFID12179OkNg"
                      :class="{ 'disabled-btn': data.Recodes.FID12179.disabled || allDisabled11969 || disabledFID12179OkNg }"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      value="NG"
                      :color="
                        data.Recodes.FID12179.value == 'NG'
                          ? data.Recodes.FID12179.color
                          : data.Recodes.FID12179.disableColor
                      "
                      :disabled="data.Recodes.FID12179.disabled || allDisabled11969 || disabledFID12179OkNg"
                      :class="{ 'disabled-btn': data.Recodes.FID12179.disabled || allDisabled11969 || disabledFID12179OkNg }"
                    >
                      NG
                    </v-btn>
                    <v-btn
                      value="該当なし"
                      :color="
                        data.Recodes.FID12179.value == '該当なし'
                          ? data.Recodes.FID12179.color
                          : data.Recodes.FID12179.disableColor
                      "
                      :disabled="data.Recodes.FID12179.disabled || allDisabled11969 || disabledFID12179NoApplicable"
                      :class="{ 'disabled-btn': data.Recodes.FID12179.disabled || allDisabled11969 || disabledFID12179OkNg }"
                    >
                      該当なし
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="10" class="pt-0">
                  <div
                    label=""
                    background-color="white"
                    hide-details="auto"
                    style="color: rgba(0, 0, 0, 0.6); font-size: 12px"
                  >
                    ※不備項目が有の場合には、再発防止策の運用はOKかNGを選択してください
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12180"
                    class="plainText12180 text-caption"
                    hide-details="auto"
                  >
                    今回
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="4" lg="4">
                  <v-btn-toggle
                    label="今回"
                    v-model="data.Recodes.FID12181.value"
                    :disabled="
                      data.Recodes.FID12181.disabled || allDisabled11969
                    "
                    background-color="white"
                    id="FID12181"
                    class="radio-group"
                    hide-details="auto"
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12181.value == '〇'
                          ? data.Recodes.FID12181.color
                          : data.Recodes.FID12181.disableColor
                      "
                      :disabled="data.Recodes.FID12181.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12181.disabled }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12181.value == '×'
                          ? data.Recodes.FID12181.color
                          : data.Recodes.FID12181.disableColor
                      "
                      :disabled="data.Recodes.FID12181.disabled"
                      :class="{ 'disabled-btn': data.Recodes.FID12181.disabled }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-right align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID12182"
                    class="plainText12182 text-caption"
                    :class="classRequired(data.Recodes.FID12183)"
                    hide-details="auto"
                  >
                    改善確認
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-btn-toggle
                    label="改善確認"
                    v-model="data.Recodes.FID12183.value"
                    :disabled="
                      data.Recodes.FID12183.disabled || allDisabled11969
                    "
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID12183"
                    class="radio-group"
                    hide-details="auto"
                    :hint="hint12183"
                    persistent-hint
                  >
                    <v-btn
                      value="〇"
                      :color="
                        data.Recodes.FID12183.value == '〇'
                          ? data.Recodes.FID12183.color
                          : data.Recodes.FID12183.disableColor
                      "
                      :disabled="data.Recodes.FID12183.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12183.disabled || allDisabled11969 }"
                    >
                      〇
                    </v-btn>
                    <v-btn
                      value="×"
                      :color="
                        data.Recodes.FID12183.value == '×'
                          ? data.Recodes.FID12183.color
                          : data.Recodes.FID12183.disableColor
                      "
                      :disabled="data.Recodes.FID12183.disabled || allDisabled11969"
                      :class="{ 'disabled-btn': data.Recodes.FID12183.disabled || allDisabled11969 }"
                    >
                      ×
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="showImage7"
                    label="画像表示"
                    hide-details
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="showImage7">
                <v-row
                  v-if="
                    typeof data.Recodes.FID13714.subReports != 'undefined' &&
                    data.Recodes.FID13714.subReports.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    v-for="(report, i) in data.Recodes.FID13714.subReports"
                    :key="`[13714-${i}`"
                  >
                    <subPagePatrolUpload7
                      label=""
                      :disabled="allDisabled11969"
                      background-color="white"
                      :id="'FID13714-' + i"
                      class="subPage13714"
                      ref="subPage13714"
                      :Report="report"
                      :subindex="i"
                      :daleteReports.sync="data.DeleteSubReports"
                      v-on:clickSubmit="saveSubPage"
                      :myInit="subInit"
                      FID="FID13714"
                      hide-details="auto"
                    ></subPagePatrolUpload7>
                    <v-col align="right">
                      <v-btn
                        @click="changeSubPageFID13714(i, i + 1)"
                        color="green"
                        class="mt-2 mp-2"
                        :disabled="i + 1 >= data.Recodes.FID13714.subReports.length"
                      >
                        ↓
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <v-btn
                      @click="subPageDel('FID13714')"
                      :disabled="allDisabled11969"
                      color="orange white--text"
                      class="ma-2"
                    >
                      画像削除
                    </v-btn>
                    <v-btn
                      @click="subPageAdd('FID13714')"
                      :disabled="allDisabled11969"
                      color="light-blue white--text"
                      class="ma-2"
                    >
                      画像追加
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template v-if="isShowInspection8">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11805"
                    class="divider11805"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11885"
                    class="plainText11885 text-body-1"
                    hide-details="auto"
                  >
                    指導（改善）内容とりまとめ（今回指導事項・お願いしたいこと）
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  typeof data.Recodes.FID11984.subReports != 'undefined' &&
                  data.Recodes.FID11984.subReports.length > 0
                "
              >
                <v-col
                  cols="12"
                  v-for="(report, i) in data.Recodes.FID11984.subReports"
                  :key="`[11984-${i}`"
                >
                  <subPagePatrolReportCheck
                    label=""
                    :allDisabled="allDisabled11969"
                    background-color="white"
                    :id="'FID11984-' + i"
                    class="subPage11984"
                    ref="subPage11984"
                    :Report="report"
                    :subindex="i"
                    :daleteReports.sync="data.DeleteSubReports"
                    v-on:clickSubmit="saveSubPage"
                    :myInit="subInit"
                    FID="FID11984"
                    :mainRecodes="data.Recodes"
                    hide-details="auto"
                  ></subPagePatrolReportCheck>
                </v-col>
              </v-row>
              <v-row> </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <v-btn
                    label="改善予定日確定"
                    :disabled="
                      data.Recodes.FID12023.disabled ||
                      inputRuleFID12023() ||
                      allDisabled11969
                    "
                    background-color="white"
                    id="FID12023"
                    class="button12023"
                    @click="onBtn12023"
                    color="light-blue white--text"
                    block
                    style="font-size: 20px; letter-spacing: 10px"
                    height="50"
                    hide-details="auto"
                  >
                    改善予定日確定
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <v-btn
                    label="改善完了報告"
                    :disabled="
                      data.Recodes.FID11988.disabled ||
                      inputRuleFID11988() ||
                      disabledFID11988
                    "
                    background-color="white"
                    id="FID11988"
                    class="button11988"
                    @click="onBtn11988"
                    color="green white--text"
                    block
                    style="font-size: 20px; letter-spacing: 10px"
                    height="50"
                    hide-details="auto"
                  >
                    改善完了報告
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-btn
                    label="GM確認"
                    :disabled="
                      data.Recodes.FID11985.disabled ||
                      inputRuleFID11985() ||
                      allDisabled11969
                    "
                    background-color="white"
                    id="FID11985"
                    class="button11985"
                    @click="onBtn11985"
                    color="primary white--text"
                    block
                    style="font-size: 20px; letter-spacing: 10px"
                    height="50"
                    hide-details="auto"
                  >
                    GM確認
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6" lg="6">
                  <v-text-field
                    label="指導（改善）完了日"
                    v-model="data.Recodes.FID11774.value"
                    :disabled="data.Recodes.FID11774.disabled || allDisabled11969"
                    :background-color="inputColor(data.Recodes.FID11774)"
                    id="FID11774"
                    class="textfield11774"
                    outlined
                    readonly
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="4">
                  <v-text-field
                    label="対応者名（面談者）"
                    v-model="data.Recodes.FID11775.value"
                    :disabled="allDisabled11969"
                    :background-color="inputColor(data.Recodes.FID11775)"
                    id="FID11775"
                    class="textfield11775"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-divider
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11888"
                    class="divider11888"
                    :style="'border-bottom-width: 3px !important;'"
                    color="black"
                    hide-details="auto"
                  ></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="align-self-center"
                >
                  <div
                    label=""
                    :disabled="allDisabled11969"
                    background-color="white"
                    id="FID11909"
                    class="plainText11909 text-body-1"
                    hide-details="auto"
                  >
                    SSからの要望・提案
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea
                    label="テキスト"
                    v-model="data.Recodes.FID11910.value"
                    :disabled="allDisabled11969"
                    :background-color="inputColor(data.Recodes.FID11910)"
                    id="FID11910"
                    class="textfield11910"
                    outlined
                    rows="3"
                    auto-grow
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-menu
                    v-model="data.Recodes.FID11927.modal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="対応・処理予定日"
                        v-model="data.Recodes.FID11927.value"
                        :disabled="allDisabled11969"
                        :background-color="inputColor(data.Recodes.FID11927)"
                        id="FID11927"
                        class="inputTime11927"
                        append-icon="mdi-calendar"
                        outlined
                        v-on="on"
                        @click:append="on.click"
                        readonly
                        hide-details="auto"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.Recodes.FID11927.value"
                      no-title
                      scrollable
                      locale="ja-jp"
                      :day-format="(date) => new Date(date).getDate()"
                      @input="data.Recodes.FID11927.modal = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11969"
                  background-color="white"
                  id="FID11805"
                  class="divider11805"
                  :style="'border-bottom-width: 3px !important;'"
                  color="black"
                  hide-details="auto"
                ></v-divider>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-btn
                  label="完了"
                  :disabled="data.Recodes.FID11772.disabled || allDisabled11969"
                  background-color="white"
                  id="FID11772"
                  class="buttonFID11772"
                  @click="vtnModalFID11772 = true"
                  color="primary"
                  block
                  style="font-size: 20px; letter-spacing: 10px"
                  height="50"
                  hide-details="auto"
                >
                  完了
                </v-btn>
                <v-dialog v-model="vtnModalFID11772" max-width="400">
                  <v-card>
                    <v-card-title class="text-h5">
                      現在の入力内容で登録を完了してもよろしいでしょうか？
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="mr-3"
                        color="green darken-1 white--text"
                        @click="onBtn11772(), (vtnModalFID11772 = false)"
                        large
                      >
                        はい
                      </v-btn>
                      <v-btn
                        color="red darken-1 white--text"
                        @click="vtnModalFID11772 = false"
                        large
                      >
                        いいえ
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <!-- <v-col cols="12" sm="12" md="4" lg="4">
                <v-btn
                  label="完了"
                  :disabled="
                    data.Recodes.FID11772.disabled ||
                    allDisabled11969
                  "
                  background-color="white"
                  id="FID11772"
                  class="button11772"
                  @click="onBtn11772"
                  color="primary white--text"
                  block
                  style="font-size: 20px; letter-spacing: 10px"
                  height="50"
                  hide-details="auto"
                >
                  完了</v-btn
                >
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="auto" sm="auto" md="auto" lg="auto">
                <v-btn
                  label="▲"
                  background-color="white"
                  id="FID12255"
                  class="button12255"
                  @click="onBtn12255"
                  color="light-blue"
                  small
                  fixed
                  :style="'right: 50px; bottom: 55px;'"
                  hide-details="auto"
                >
                  ▲
                </v-btn>
              </v-col>
              <v-col cols="auto" sm="auto" md="auto" lg="auto">
                <v-btn
                  label="▼"
                  background-color="white"
                  id="FID13151"
                  class="button13151"
                  @click="onBtn13151"
                  color="light-blue"
                  small
                  fixed
                  :style="'right: 100px; bottom: 55px;'"
                  hide-details="auto"
                >
                  ▼
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="left">
                <v-btn
                  @click="deleteModal = true"
                  :disabled="data.ReportID == null || userRole == '一般'"
                  color="red white--text"
                >
                  削除
                </v-btn>
                <v-dialog v-model="deleteModal" max-width="400">
                  <v-card>
                    <v-card-title class="text-h5">
                      削除しますか？
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="mr-3"
                        color="green darken-1 white--text"
                        @click="deleteModal = false"
                        large
                      >
                        いいえ
                      </v-btn>
                      <v-btn
                        color="red darken-1 white--text"
                        @click="del"
                        large
                      >
                        はい
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col v-if="showTemplate11771">
                <v-btn
                  @click="save11771()"
                  color="yellow"
                  :disabled="allDisabled11969"
                  fixed
                  bottom
                  :style="'right:+50px;'"
                >
                  一時保存
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const api_extra = RepositoryFactory.get("seisei_back_extra");

import { statusCheck } from "./specialMethod";
import { watchFromID11761 } from "./specialMethod";
import { watchFromID11762 } from "./specialMethod";
import patrolreportCreateSpecial from "../views/patrolreportCreateSpecial";
import { disabledNow } from "./specialMethod";
import inputNumber from "../views/inputNumber";
import subPagePatrolReportCheck from "../views/subPagePatrolReportCheck";
import { disabledFID12023 } from "./specialMethod";
import { collBtn12023No0 } from "./specialMethod";
import { disabledFID11988 } from "./specialMethod";
import { collBtn11988No0 } from "./specialMethod";
import { disabledFID11985 } from "./specialMethod";
import { collBtn11985No0 } from "./specialMethod";
import { spInputColor } from "./specialMethod";
import subPagePatrolUpload from "../views/subPagePatrolUpload";
import patrolReportNavigationMenu from "../views/patrolReportNavigationMenu";
import subPagePatrolUpload2 from "../views/subPagePatrolUpload2";
import subPagePatrolUpload3 from "../views/subPagePatrolUpload3";
import subPagePatrolUpload4 from "../views/subPagePatrolUpload4";
import subPagePatrolUpload5 from "../views/subPagePatrolUpload5";
import subPagePatrolUpload6 from "../views/subPagePatrolUpload6";
import subPagePatrolUpload7 from "../views/subPagePatrolUpload7";

export default {
  name: "Home",
  components: {
    patrolreportCreateSpecial,
    inputNumber,
    subPagePatrolReportCheck,
    subPagePatrolUpload,
    patrolReportNavigationMenu,
    subPagePatrolUpload2,
    subPagePatrolUpload3,
    subPagePatrolUpload4,
    subPagePatrolUpload5,
    subPagePatrolUpload6,
    subPagePatrolUpload7,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1651,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID12222: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID11227: { value: "", formType: 1, disabled: false }, //廃止:行動指針
          FID11758: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: true,
            modal: false,
          },
          FID11873: { value: "", formType: 1, disabled: false },
          FID11759: { value: "", formType: 16, disabled: false },
          FID11760: { value: "", formType: 16, disabled: false },
          FID11761: { value: "", formType: 1, disabled: true },
          FID11762: { value: "", formType: 1, disabled: true },
          FID11763: { value: "", formType: 1, disabled: true },
          FID11764: { value: "", formType: 1, disabled: true },
          FID11765: { value: "", formType: 1, disabled: true },
          FID11766: { value: "", formType: 18, disabled: false },
          FID11767: { value: "", formType: 1, disabled: true },
          FID11768: { value: "", formType: 1, disabled: true },
          FID11770: { value: "", formType: 1, disabled: false },
          FID11864: { value: "", formType: 16, disabled: false },
          FID11935: { value: "", formType: 20, disabled: false },
          FID11841: {
            value: "",
            formType: 23,
            disabled: false,
            option: "DateNum:ABC-YYYY-MM-DD-NNNN",
          },
          FID11844: { value: "", formType: 1, disabled: false },
          FID11954: { value: "", formType: 1, disabled: false },
          FID11955: { value: "", formType: 1, disabled: false },
          FID11787: { value: "", formType: 8, disabled: false },
          FID11989: { value: "0", formType: 1, disabled: false },
          FID11788: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID11789: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID11792: { value: "", formType: 8, disabled: false },
          FID11990: { value: "0", formType: 1, disabled: false },
          FID11793: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID11794: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID11797: { value: "", formType: 8, disabled: false },
          FID11991: { value: "0", formType: 1, disabled: false },
          FID11798: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID11799: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID11802: { value: "", formType: 8, disabled: false },
          FID11992: { value: "0", formType: 1, disabled: false },
          FID11803: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID11804: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12038: { value: "0", formType: 1, disabled: false },
          FID12032: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12033: { value: "0", formType: 1, disabled: false, modal: false },
          FID12035: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12037: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12041: { value: "0", formType: 1, disabled: false },
          FID12043: { value: "", formType: 1, disabled: false },
          FID12045: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12042: { value: "0", formType: 1, disabled: false, modal: false },
          FID12047: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12049: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12052: { value: "0", formType: 1, disabled: false },
          FID12055: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12058: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12061: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12063: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12065: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12068: { value: "0", formType: 1, disabled: false },
          FID12070: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12075: { value: "", formType: 1, disabled: false },
          FID12076: { value: "0", formType: 1, disabled: false, modal: false },
          FID12072: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12074: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12089: { value: "0", formType: 1, disabled: false },
          FID12079: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12080: { value: "", formType: 1, disabled: false },
          FID12082: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12084: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12086: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12088: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12092: { value: "0", formType: 1, disabled: false },
          FID12094: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12095: { value: "", formType: 1, disabled: false, modal: false },
          FID12097: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12099: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12101: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12106: { value: "0", formType: 1, disabled: false },
          FID12108: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12109: { value: "", formType: 1, disabled: false, modal: false },
          FID12111: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12113: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12115: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12118: { value: "0", formType: 1, disabled: false },
          FID12120: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12122: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12124: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12126: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12128: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12130: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12133: { value: "0", formType: 1, disabled: false },
          FID12136: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12138: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12140: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12142: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12147: { value: "0", formType: 1, disabled: false },
          FID12148: { value: "", formType: 1, disabled: false, modal: false },
          FID12150: { value: "", formType: 1, disabled: false, modal: false },
          FID12152: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12154: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12156: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12159: { value: "0", formType: 1, disabled: false },
          FID12161: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12163: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12165: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12167: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12169: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12172: { value: "0", formType: 1, disabled: false },
          FID12175: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12177: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12179: {
            value: "",
            formType: 7,
            disabled: false,
            color: "light-blue",
            disableColor: "white",
          },
          FID12181: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID12183: {
            value: "",
            formType: 7,
            disabled: false,
            color: "orange",
            disableColor: "white",
          },
          FID11984: {
            value: "PID1678",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID12204: { value: "", formType: 1, disabled: false },
          FID12023: { value: "", formType: 16, disabled: true },
          FID11988: { value: "", formType: 16, disabled: false },
          FID11985: { value: "", formType: 16, disabled: false },
          FID11774: { value: "", formType: 1, disabled: true },
          FID11775: { value: "", formType: 1, disabled: false },
          FID11910: { value: "", formType: 8, disabled: false },
          FID11927: { value: "", formType: 3, disabled: false, modal: false },
          FID11836: {
            value: "PID1672",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID11772: { value: "", formType: 16, disabled: false },
          FID11771: { value: "", formType: 20, disabled: false },
          FID12255: { value: "", formType: 16, disabled: false },
          FID12267: { value: "", formType: 16, disabled: false }, //SS認証指定
          FID13151: { value: "", formType: 16, disabled: false },
          FID13377: { value: "", formType: 1, disabled: false }, //事業部コード
          FID13630: {
            value: "PID1885",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID13710: {
            value: "PID1908",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID13711: {
            value: "PID1909",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID13712: {
            value: "PID1910",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID13713: {
            value: "PID1911",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID13714: {
            value: "PID1912",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
        },
        DeleteSubReports: [],
      },
      UserRecodes: {
        FID0: {
          value: "",
          formType: 21,
          name: "",
          mail: "eneosPatrol@demo.service.kakanai.com",
          role: "0",
          password: "",
          account: "",
          attribute: "",
        },
        FID12259: { value: "0", formType: 1, disabled: false },//事業部コード
        FID12260: { value: "", formType: 1, disabled: false },//現在組織正式名称4
        FID12261: { value: "", formType: 1, disabled: false },//役職
        FID12262: { value: "", formType: 1, disabled: false }, // (非)権限
        FID13317: { value: "", formType: 1, disabled: false },//氏名
        FID13318: { value: "", formType: 1, disabled: false },//現在組織正式名称
        FID13319: { value: "0", formType: 1, disabled: false },//SSコード
        FID13320: { value: "", formType: 1, disabled: false },//社員コード（usernameと同じ、検索用）
        FID13380: { value: "", formType: 14, disabled: false },//人事情報取込時：更新・削除不可
      },
      vtnModal11772: false,
      validStock: false,
      validMoney: false,
      showImage1: false,
      showImage2: false,
      showImage3: false,
      showImage4: false,
      showImage5: false,
      showImage6: false,
      showImage7: false,

      rules: {
        required: (value) => !!value || "必須項目です",
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },
        //実現金①：現金差異（円）
        checkFID12033: () => {
          if (
            this.data.Recodes.FID12032.value == "有" &&
            this.data.Recodes.FID12033.value == "0"
          ) {
            return "金額を入力して下さい。";
          }
          return true;
        },
        //実現金②：釣銭機差異（円）
        checkFID12042: () => {
          if (
            this.data.Recodes.FID12045.value == "有" &&
            this.data.Recodes.FID12042.value == "0"
          ) {
            return "金額を入力して下さい。";
          }
          return true;
        },
        //実現金②：釣銭機ナンバー
        checkFID12043: () => {
          if (
            (this.data.Recodes.FID12045.value == "有" &&
              this.data.Recodes.FID12043.value == "") ||
            (this.data.Recodes.FID12042.value !== "0" &&
              this.data.Recodes.FID12043.value == "")
          ) {
            return "釣銭機ナンバーを入力して下さい。";
          }
          return true;
        },
        //実現金④：商品名
        checkFID12075: () => {
          if (
            this.data.Recodes.FID12070.value == "有" &&
            this.data.Recodes.FID12075.value == ""
          ) {
            return "商品名を入力して下さい。";
          }
          return true;
        },
        //実現金④：金額
        checkFID12076: () => {
          if (
            this.data.Recodes.FID12070.value == "有" &&
            this.data.Recodes.FID12076.value == "0"
          ) {
            return "金額を入力して下さい。";
          }
          return true;
        },
        //在庫⑤-1：在庫差異
        checkFID12095: () => {
          if (
            this.data.Recodes.FID12094.value == "有" &&
            this.data.Recodes.FID12095.value == ""
          ) {
            return "差異個数を入力して下さい。";
          }
          return true;
        },
        //在庫⑤-2：バッテリー差異
        checkFID12109: () => {
          if (
            this.data.Recodes.FID12108.value == "有" &&
            this.data.Recodes.FID12109.value == ""
          ) {
            return "差異個数を入力して下さい。";
          }
          return true;
        },
        //分解整備⑧：分解記録簿件数（件）(1)
        checkFID12148: () => {
          if (
            this.data.Recodes.FID12154.value !== "" &&
            this.data.Recodes.FID12154.value !== "該当なし" &&
            this.data.Recodes.FID12148.value == ""
          ) {
            return "分解記録簿件数（件）を入力して下さい。";
          }
          return true;
        },
        //分解整備⑧：分解記録簿件数（件）(2)
        checkFID12150: () => {
          if (
            this.data.Recodes.FID12154.value !== "" &&
            this.data.Recodes.FID12154.value !== "該当なし" &&
            this.data.Recodes.FID12150.value == ""
          ) {
            return "分解記録簿件数（件）を入力して下さい。";
          }
          return true;
        },
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
      // 対象外機能
      kaizenPlanBtn: false,
      glBtn: false,
      inputCompleteBtn: false,
      vtnModalFID11772: false,
      inspectionItem: "0", //点検項目選択
      inspectionItems: [
        { value: "0", label: "全て" },
        {
          value: "1",
          label:
            "1.第一印象・クレンリネス＆掲示物（フィールド/ゲストルーム/スタッフルーム/ピット）",
        },
        {
          value: "2",
          label: "2.設備（固定資産/リース/社有車/各種設備/PC/消防関連）",
        },
        {
          value: "3",
          label: "3.マネジメントツール・人材育成＆教育",
        },
        {
          value: "4",
          label:
            "4.コンプライアンス（違法改造車/未認証での分解整備/不正な労働管理）",
        },
        {
          value: "5",
          label: "5.実査（実現金/タイヤ・バッテリー在庫/黒本・施設点検）",
        },
        {
          value: "6",
          label: "6.SS併設認証【認証併設SSのみ】",
        },
        {
          value: "7",
          label: "7.「SS管理チェック」実施状況の確認",
        },
        {
          value: "8",
          label: "指導（改善）内容とりまとめ",
        },
      ],
      isShowInspection1: true,
      isShowInspection2: true,
      isShowInspection3: true,
      isShowInspection4: true,
      isShowInspection5: true,
      isShowInspection6: true,
      isShowInspection7: true,
      isShowInspection8: true,
    };
  },
  async created() {
    await this.getInit();
  },

  computed: {
    hint11227: function () {
      return "※入力必須";
    },
    hint11770: function () {
      return "※入力必須";
    },
    showTemplate11935() {
      return true;
    },
    hint11793: function () {
      return "*入力必須";
    },
    hint11794: function () {
      return "*入力必須";
    },
    hint11798: function () {
      return "*入力必須";
    },
    hint11799: function () {
      return "*入力必須";
    },
    hint11804: function () {
      return "*入力必須";
    },
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    hint12037: function () {
      return "*入力必須";
    },
    hint12049: function () {
      return "*入力必須";
    },
    hint12065: function () {
      return "*入力必須";
    },
    hint12074: function () {
      return "*入力必須";
    },
    hint12088: function () {
      return "*入力必須";
    },
    hint12101: function () {
      return "*入力必須";
    },
    hint12115: function () {
      return "*入力必須";
    },
    hint12130: function () {
      return "*入力必須";
    },
    hint12142: function () {
      return "*入力必須";
    },
    hint12156: function () {
      return "*入力必須";
    },
    hint12169: function () {
      return "*入力必須";
    },
    hint12183: function () {
      return "*入力必須";
    },
    showTemplate11771() {
      if (this.data.Recodes.FID11864.value != "") {
        return true;
      }
      return false;
    },
    NotApplicableAllDisabled() {
      if (
        this.data.Recodes.FID12023.value != "" ||
        this.data.Recodes.FID11772.value != "" ||
        this.data.Recodes.FID11985.value != ""
      ) {
        return true;
      }
      return false;
    },
    allDisabled11969() {
      let data = this.data;
      if (
        (data.Recodes.FID11772.value == "" || data.Recodes.FID11772.value == null ) &&
        (this.$store.state.role == 1 || this.$store.state.role == 2)
      ) {
        return false;
      }
      return true;
    },
    disabledFID11988() {
      let data = this.data;
      if (
        data.Recodes.FID11772.value == "" ||
        data.Recodes.FID11772.value == null
      ) {
        return false;
      }
      return true;
    },
    userRole() {
      let role = "一般";
      if (this.$store.state.role == 1) {
        role = "管理者";
      } else if (this.$store.state.role == 2) {
        role = "グループ管理者";
      }
      return role;
    },
    disabledFID12179OkNg() {
      if (this.data.Recodes.FID12177.value == "無") {
        return true
      }
      return false
    },
    disabledFID12179NoApplicable() {
      if (this.data.Recodes.FID12177.value == "有") {
        return true
      }
      return false
    }
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //巡回開始/1回のみ
        if (data.Recodes.FID11759.value != "") {
          this.data.Recodes.FID11759.disabled = true;
        } else {
          this.data.Recodes.FID11759.disabled = false;
        }
        //巡回開始押下で巡回終了押下可能/1回のみ
        if (
          data.Recodes.FID11759.value != "" &&
          (data.Recodes.FID11760.value == "" ||
            data.Recodes.FID11760.value == null)
        ) {
          this.data.Recodes.FID11760.disabled = false;
        } else {
          this.data.Recodes.FID11760.disabled = true;
        }
        //情報入力完了押下可能/1回のみ 必須情報：行動指針・GM（巡回者）・対応者名（面談者名）
        if (
          // data.Recodes.FID11227.value == "" ||
          // data.Recodes.FID11227.value == null ||
          data.Recodes.FID11766.value == "" ||
          data.Recodes.FID11766.value == null ||
          data.Recodes.FID11770.value == "" ||
          data.Recodes.FID11770.value == null ||
          data.Recodes.FID11864.value != ""
        ) {
          this.data.Recodes.FID11864.disabled = true;
        } else {
          this.data.Recodes.FID11864.disabled = false;
        }
        //1.「今回」の入力可能・不可の判定を返す true: 入力不可 false: 入力可能
        if (await disabledNow(data)) {
          this.data.Recodes.FID11788.disabled = true;
        } else {
          this.data.Recodes.FID11788.disabled = false;
        }
        //1.「改善確認」の入力可能・不可の判定を返す true: 入力不可 false: 入力可能
        if (
          data.Recodes.FID11788.value != "×" ||
          data.Recodes.FID11988.value == "" || //改善完了報告
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != "" //GM確認
        ) {
          this.data.Recodes.FID11789.disabled = true;
        } else {
          this.data.Recodes.FID11789.disabled = false;
        }
        //2.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID11793.disabled = true;
        } else {
          this.data.Recodes.FID11793.disabled = false;
        }
        //2.改善確認
        if (
          data.Recodes.FID11793.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          this.data.Recodes.FID11794.disabled = true;
        } else {
          this.data.Recodes.FID11794.disabled = false;
        }
        //3.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID11798.disabled = true;
        } else {
          this.data.Recodes.FID11798.disabled = false;
        }
        //3.改善確認
        if (
          data.Recodes.FID11798.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          this.data.Recodes.FID11799.disabled = true;
        } else {
          this.data.Recodes.FID11799.disabled = false;
        }
        //4.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID11803.disabled = true;
        } else {
          this.data.Recodes.FID11803.disabled = false;
        }
        //4.改善確認
        if (
          data.Recodes.FID11803.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          this.data.Recodes.FID11804.disabled = true;
        } else {
          this.data.Recodes.FID11804.disabled = false;
        }
        //5.現金差異
        if (await disabledNow(data)) {
          this.data.Recodes.FID12032.disabled = true;
        } else {
          this.data.Recodes.FID12032.disabled = false;
        }
        //5.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12035.disabled = true;
        } else if (
          data.Recodes.FID12032.value == "" ||
          data.Recodes.FID12032.value == null
        ) {
          if (this.data.Recodes.FID12035.value !== "該当なし")
            this.data.Recodes.FID12035.value = "";
          this.data.Recodes.FID12035.disabled = true;
        } else if (data.Recodes.FID12032.value == "有") {
          this.data.Recodes.FID12035.value = "×";
          this.data.Recodes.FID12035.disabled = false;
        } else {
          this.data.Recodes.FID12035.value = "〇";
          this.data.Recodes.FID12035.disabled = false;
        }
        //5.改善確認
        if (
          data.Recodes.FID12035.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != "" ||
          data.Recodes.FID12035.value == "該当なし"
        ) {
          this.data.Recodes.FID12037.disabled = true;
        } else {
          this.data.Recodes.FID12037.disabled = false;
        }
        //6.釣銭機差異
        if (await disabledNow(data)) {
          this.data.Recodes.FID12045.disabled = true;
        } else if (data.Recodes.FID12047.value == "該当なし") {
          this.data.Recodes.FID12045.value = ""; //値クリア
          this.data.Recodes.FID12045.disabled = true;
        } else {
          this.data.Recodes.FID12045.disabled = false;
        }
        //6.釣銭機ナンバー
        if (data.Recodes.FID12047.value == "該当なし") {
          this.data.Recodes.FID12043.value = ""; //値クリア
          this.data.Recodes.FID12043.disabled = true;
        } else {
          this.data.Recodes.FID12043.disabled = false;
        }
        //6.釣銭機差異（円）
        if (data.Recodes.FID12047.value == "該当なし") {
          this.data.Recodes.FID12042.value = "0"; //値クリア
          this.data.Recodes.FID12042.disabled = true;
        } else {
          this.data.Recodes.FID12042.disabled = false;
        }
        //6.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12047.disabled = true;
        } else if (
          data.Recodes.FID12045.value == "" ||
          data.Recodes.FID12045.value == null
        ) {
          if (this.data.Recodes.FID12047.value !== "該当なし")
            this.data.Recodes.FID12047.value = "";
          this.data.Recodes.FID12047.disabled = true;
        } else if (data.Recodes.FID12045.value == "有") {
          this.data.Recodes.FID12047.value = "×";
          this.data.Recodes.FID12047.disabled = false;
        } else {
          this.data.Recodes.FID12047.value = "〇";
          this.data.Recodes.FID12047.disabled = false;
        }
        //6.改善確認
        if (
          data.Recodes.FID12047.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          if (data.Recodes.FID12047.value == "該当なし") {
            this.data.Recodes.FID12049.value = ""; //値クリア
          }
          this.data.Recodes.FID12049.disabled = true;
        } else {
          this.data.Recodes.FID12049.disabled = false;
        }
        //7.SSログ（当月累計回数）
        if (await disabledNow(data)) {
          this.data.Recodes.FID12055.disabled = true;
        } else if (data.Recodes.FID12063.value == "該当なし") {
          this.data.Recodes.FID12055.value = ""; //値クリア
          this.data.Recodes.FID12055.disabled = true;
        } else {
          this.data.Recodes.FID12055.disabled = false;
        }
        //7.管理者ログ1（詳細）
        if (await disabledNow(data)) {
          this.data.Recodes.FID12058.disabled = true;
        } else if (data.Recodes.FID12063.value == "該当なし") {
          this.data.Recodes.FID12058.value = ""; //値クリア
          this.data.Recodes.FID12058.disabled = true;
        } else {
          this.data.Recodes.FID12058.disabled = false;
        }
        //7.SSログ（詳細）
        if (await disabledNow(data)) {
          this.data.Recodes.FID12061.disabled = true;
        } else if (data.Recodes.FID12063.value == "該当なし") {
          this.data.Recodes.FID12061.value = ""; //値クリア
          this.data.Recodes.FID12061.disabled = true;
        } else {
          this.data.Recodes.FID12061.disabled = false;
        }
        //7.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12063.disabled = true;
        } else if (
          data.Recodes.FID12055.value == "" ||
          data.Recodes.FID12055.value == null ||
          data.Recodes.FID12058.value == "" ||
          data.Recodes.FID12058.value == null ||
          data.Recodes.FID12061.value == "" ||
          data.Recodes.FID12061.value == null
        ) {
          if (this.data.Recodes.FID12063.value !== "該当なし")
            this.data.Recodes.FID12063.value = "";
          this.data.Recodes.FID12063.disabled = true;
        } else if (
          data.Recodes.FID12055.value == "有" ||
          data.Recodes.FID12058.value == "有" ||
          data.Recodes.FID12061.value == "有"
        ) {
          this.data.Recodes.FID12063.value = "×";
          this.data.Recodes.FID12063.disabled = false;
        } else {
          this.data.Recodes.FID12063.value = "〇";
          this.data.Recodes.FID12063.disabled = false;
        }
        //7.改善確認
        if (
          data.Recodes.FID12063.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          if (data.Recodes.FID12063.value == "該当なし") {
            this.data.Recodes.FID12065.value = ""; //値クリア
          }
          this.data.Recodes.FID12065.disabled = true;
        } else {
          this.data.Recodes.FID12065.disabled = false;
        }
        //8.先売上
        if (await disabledNow(data)) {
          this.data.Recodes.FID12070.disabled = true;
        } else if (data.Recodes.FID12072.value == "該当なし") {
          this.data.Recodes.FID12070.value = ""; //値クリア
          this.data.Recodes.FID12070.disabled = true;
        } else {
          this.data.Recodes.FID12070.disabled = false;
        }
        //8.商品名
        if (data.Recodes.FID12072.value == "該当なし") {
          this.data.Recodes.FID12075.value = ""; //値クリア
          this.data.Recodes.FID12075.disabled = true;
        } else {
          this.data.Recodes.FID12075.disabled = false;
        }
        //8.金額
        if (data.Recodes.FID12072.value == "該当なし") {
          this.data.Recodes.FID12076.value = "0"; //値クリア
          this.data.Recodes.FID12076.disabled = true;
        } else {
          this.data.Recodes.FID12076.disabled = false;
        }
        //8.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12072.disabled = true;
        } else if (
          data.Recodes.FID12070.value == "" ||
          data.Recodes.FID12070.value == null
        ) {
          if (this.data.Recodes.FID12072.value !== "該当なし")
            this.data.Recodes.FID12072.value = "";
          this.data.Recodes.FID12072.disabled = true;
        } else if (data.Recodes.FID12070.value == "有") {
          this.data.Recodes.FID12072.value = "×";
          this.data.Recodes.FID12072.disabled = false;
        } else {
          this.data.Recodes.FID12072.value = "〇";
          this.data.Recodes.FID12072.disabled = false;
        }
        //8.改善確認
        if (
          data.Recodes.FID12072.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          if (data.Recodes.FID12072.value == "該当なし") {
            this.data.Recodes.FID12074.value = ""; //値クリア
          }
          this.data.Recodes.FID12074.disabled = true;
        } else {
          this.data.Recodes.FID12074.disabled = false;
        }
        //9.規定外保管物
        if (await disabledNow(data)) {
          this.data.Recodes.FID12079.disabled = true;
        } else {
          this.data.Recodes.FID12079.disabled = false;
        }
        //9.その他金券類
        if (await disabledNow(data)) {
          this.data.Recodes.FID12082.disabled = true;
        } else {
          this.data.Recodes.FID12082.disabled = false;
        }
        //9.受払表作成
        if (await disabledNow(data)) {
          this.data.Recodes.FID12084.disabled = true;
        } else {
          this.data.Recodes.FID12084.disabled = false;
        }
        //9.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12086.disabled = true;
        } else if (
          data.Recodes.FID12079.value == "" ||
          data.Recodes.FID12079.value == null ||
          data.Recodes.FID12082.value == "" ||
          data.Recodes.FID12082.value == null
        ) {
          if (this.data.Recodes.FID12086.value !== "該当なし")
            this.data.Recodes.FID12086.value = "";
          this.data.Recodes.FID12086.disabled = true;
        } else if (
          data.Recodes.FID12079.value == "有" ||
          data.Recodes.FID12082.value == "有"
        ) {
          this.data.Recodes.FID12086.value = "×";
          this.data.Recodes.FID12086.disabled = false;
        } else {
          this.data.Recodes.FID12086.value = "〇";
          this.data.Recodes.FID12086.disabled = false;
        }
        //9.改善確認
        if (
          data.Recodes.FID12086.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != "" ||
          data.Recodes.FID12086.value == "該当なし"
        ) {
          this.data.Recodes.FID12088.disabled = true;
        } else {
          this.data.Recodes.FID12088.disabled = false;
        }
        //10.タイヤ差異
        if (await disabledNow(data)) {
          this.data.Recodes.FID12094.disabled = true;
        } else if (data.Recodes.FID12099.value == "該当なし") {
          this.data.Recodes.FID12094.value = ""; //値クリア
          this.data.Recodes.FID12094.disabled = true;
        } else {
          this.data.Recodes.FID12094.disabled = false;
        }
        //10.差異個数
        if (data.Recodes.FID12099.value == "該当なし") {
          this.data.Recodes.FID12095.value = ""; //値クリア
          this.data.Recodes.FID12095.disabled = true;
        } else {
          this.data.Recodes.FID12095.disabled = false;
        }
        //10.薄外在庫
        if (await disabledNow(data)) {
          this.data.Recodes.FID12097.disabled = true;
        } else if (data.Recodes.FID12099.value == "該当なし") {
          this.data.Recodes.FID12097.value = ""; //値クリア
          this.data.Recodes.FID12097.disabled = true;
        } else {
          this.data.Recodes.FID12097.disabled = false;
        }
        //10.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12099.disabled = true;
        } else if (
          data.Recodes.FID12094.value == "" ||
          data.Recodes.FID12094.value == null ||
          data.Recodes.FID12097.value == "" ||
          data.Recodes.FID12097.value == null
        ) {
          if (this.data.Recodes.FID12099.value !== "該当なし")
            this.data.Recodes.FID12099.value = "";
          this.data.Recodes.FID12099.disabled = true;
        } else if (
          data.Recodes.FID12094.value == "有" ||
          data.Recodes.FID12097.value == "有"
        ) {
          this.data.Recodes.FID12099.value = "×";
          this.data.Recodes.FID12099.disabled = false;
        } else {
          this.data.Recodes.FID12099.value = "〇";
          this.data.Recodes.FID12099.disabled = false;
        }
        //10.改善確認
        if (
          data.Recodes.FID12099.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          if (data.Recodes.FID12099.value == "該当なし") {
            this.data.Recodes.FID12101.value = ""; //値クリア
          }
          this.data.Recodes.FID12101.disabled = true;
        } else {
          this.data.Recodes.FID12101.disabled = false;
        }
        //11.バッテリー差異
        if (await disabledNow(data)) {
          this.data.Recodes.FID12108.disabled = true;
        } else if (data.Recodes.FID12113.value == "該当なし") {
          this.data.Recodes.FID12108.value = ""; //値クリア
          this.data.Recodes.FID12108.disabled = true;
        } else {
          this.data.Recodes.FID12108.disabled = false;
        }
        //11.差異個数
        if (data.Recodes.FID12113.value == "該当なし") {
          this.data.Recodes.FID12109.value = ""; //値クリア
          this.data.Recodes.FID12109.disabled = true;
        } else {
          this.data.Recodes.FID12109.disabled = false;
        }
        //11.薄外在庫
        if (await disabledNow(data)) {
          this.data.Recodes.FID12111.disabled = true;
        } else if (data.Recodes.FID12113.value == "該当なし") {
          this.data.Recodes.FID12111.value = ""; //値クリア
          this.data.Recodes.FID12111.disabled = true;
        } else {
          this.data.Recodes.FID12111.disabled = false;
        }
        //11.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12113.disabled = true;
        } else if (
          data.Recodes.FID12108.value == "" ||
          data.Recodes.FID12108.value == null ||
          data.Recodes.FID12111.value == "" ||
          data.Recodes.FID12111.value == null
        ) {
          if (this.data.Recodes.FID12113.value !== "該当なし")
            this.data.Recodes.FID12113.value = "";
          this.data.Recodes.FID12113.disabled = true;
        } else if (
          data.Recodes.FID12108.value == "有" ||
          data.Recodes.FID12111.value == "有"
        ) {
          this.data.Recodes.FID12113.value = "×";
          this.data.Recodes.FID12113.disabled = false;
        } else {
          this.data.Recodes.FID12113.value = "〇";
          this.data.Recodes.FID12113.disabled = false;
        }
        //11.改善確認
        if (
          data.Recodes.FID12113.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          if (data.Recodes.FID12113.value == "該当なし") {
            this.data.Recodes.FID12115.value = ""; //値クリア
          }
          this.data.Recodes.FID12115.disabled = true;
        } else {
          this.data.Recodes.FID12115.disabled = false;
        }
        //12.計量機内の点検（油漏れ）
        if (await disabledNow(data)) {
          this.data.Recodes.FID12120.disabled = true;
        } else {
          this.data.Recodes.FID12120.disabled = false;
        }
        //12.油水分離槽清
        if (await disabledNow(data)) {
          this.data.Recodes.FID12122.disabled = true;
        } else {
          this.data.Recodes.FID12122.disabled = false;
        }
        //12.マンホール内の滞水
        if (await disabledNow(data)) {
          this.data.Recodes.FID12124.disabled = true;
        } else {
          this.data.Recodes.FID12124.disabled = false;
        }
        //12.消火器の期限・破損
        if (await disabledNow(data)) {
          this.data.Recodes.FID12126.disabled = true;
        } else {
          this.data.Recodes.FID12126.disabled = false;
        }
        //12.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12128.disabled = true;
        } else if (
          data.Recodes.FID12120.value == "" ||
          data.Recodes.FID12120.value == null ||
          data.Recodes.FID12122.value == "" ||
          data.Recodes.FID12122.value == null ||
          data.Recodes.FID12124.value == "" ||
          data.Recodes.FID12124.value == null ||
          data.Recodes.FID12126.value == "" ||
          data.Recodes.FID12126.value == null
        ) {
          if (this.data.Recodes.FID12128.value !== "該当なし")
            this.data.Recodes.FID12128.value = "";
          this.data.Recodes.FID12128.disabled = true;
        } else if (
          data.Recodes.FID12120.value == "NG" ||
          data.Recodes.FID12122.value == "NG" ||
          data.Recodes.FID12124.value == "NG" ||
          data.Recodes.FID12126.value == "NG"
        ) {
          this.data.Recodes.FID12128.value = "×";
          this.data.Recodes.FID12128.disabled = false;
        } else {
          this.data.Recodes.FID12128.value = "〇";
          this.data.Recodes.FID12128.disabled = false;
        }
        //12.改善確認
        if (
          data.Recodes.FID12128.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != "" ||
          data.Recodes.FID12128.value == "該当なし"
        ) {
          this.data.Recodes.FID12130.disabled = true;
        } else {
          this.data.Recodes.FID12130.disabled = false;
        }
        //13.分解整備料金表
        if (await disabledNow(data)) {
          this.data.Recodes.FID12136.disabled = true;
        } else if (data.Recodes.FID12140.value == "該当なし") {
          this.data.Recodes.FID12136.value = ""; //値クリア
          this.data.Recodes.FID12136.disabled = true;
        } else {
          this.data.Recodes.FID12136.disabled = false;
        }
        //13.分解整備工具
        if (await disabledNow(data)) {
          this.data.Recodes.FID12138.disabled = true;
        } else if (data.Recodes.FID12140.value == "該当なし") {
          this.data.Recodes.FID12138.value = ""; //値クリア
          this.data.Recodes.FID12138.disabled = true;
        } else {
          this.data.Recodes.FID12138.disabled = false;
        }
        //13.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12140.disabled = true;
        } else if (
          data.Recodes.FID12136.value == "" ||
          data.Recodes.FID12136.value == null ||
          data.Recodes.FID12138.value == "" ||
          data.Recodes.FID12138.value == null
        ) {
          if (this.data.Recodes.FID12140.value !== "該当なし")
            this.data.Recodes.FID12140.value = "";
          this.data.Recodes.FID12140.disabled = true;
        } else if (
          data.Recodes.FID12136.value == "NG" ||
          data.Recodes.FID12138.value == "NG"
        ) {
          this.data.Recodes.FID12140.value = "×";
          this.data.Recodes.FID12140.disabled = false;
        } else {
          this.data.Recodes.FID12140.value = "〇";
          this.data.Recodes.FID12140.disabled = false;
        }
        //13.改善確認
        if (
          data.Recodes.FID12140.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          if (data.Recodes.FID12140.value == "該当なし") {
            this.data.Recodes.FID12142.value = ""; //値クリア
          }
          this.data.Recodes.FID12142.disabled = true;
        } else {
          this.data.Recodes.FID12142.disabled = false;
        }
        //14.分解記録簿件数（件）
        if (data.Recodes.FID12154.value == "該当なし") {
          this.data.Recodes.FID12148.value = ""; //値クリア
          this.data.Recodes.FID12148.disabled = true;
        } else {
          this.data.Recodes.FID12148.disabled = false;
        }
        //14.分解記録簿件数（件）
        if (data.Recodes.FID12154.value == "該当なし") {
          this.data.Recodes.FID12150.value = ""; //値クリア
          this.data.Recodes.FID12150.disabled = true;
        } else {
          this.data.Recodes.FID12150.disabled = false;
        }
        //14.分解整備記録簿記載
        if (await disabledNow(data)) {
          this.data.Recodes.FID12152.disabled = true;
        } else if (data.Recodes.FID12154.value == "該当なし") {
          this.data.Recodes.FID12152.value = ""; //値クリア
          this.data.Recodes.FID12152.disabled = true;
        } else {
          this.data.Recodes.FID12152.disabled = false;
        }
        //14.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12154.disabled = true;
        } else if (
          data.Recodes.FID12152.value == "" ||
          data.Recodes.FID12152.value == null
        ) {
          if (this.data.Recodes.FID12154.value !== "該当なし")
            this.data.Recodes.FID12154.value = "";
          this.data.Recodes.FID12154.disabled = true;
        } else if (data.Recodes.FID12152.value == "NG") {
          this.data.Recodes.FID12154.value = "×";
          this.data.Recodes.FID12154.disabled = false;
        } else {
          this.data.Recodes.FID12154.value = "〇";
          this.data.Recodes.FID12154.disabled = false;
        }
        //14.改善確認
        if (
          data.Recodes.FID12154.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          if (data.Recodes.FID12154.value == "該当なし") {
            this.data.Recodes.FID12156.value = ""; //値クリア
          }
          this.data.Recodes.FID12156.disabled = true;
        } else {
          this.data.Recodes.FID12156.disabled = false;
        }
        //15.ステッカー番号記載
        if (await disabledNow(data)) {
          this.data.Recodes.FID12161.disabled = true;
        } else if (data.Recodes.FID12167.value == "該当なし") {
          this.data.Recodes.FID12161.value = ""; //値クリア
          this.data.Recodes.FID12161.disabled = true;
        } else {
          this.data.Recodes.FID12161.disabled = false;
        }
        //15.自署
        if (await disabledNow(data)) {
          this.data.Recodes.FID12163.disabled = true;
        } else if (data.Recodes.FID12167.value == "該当なし") {
          this.data.Recodes.FID12163.value = ""; //値クリア
          this.data.Recodes.FID12163.disabled = true;
        } else {
          this.data.Recodes.FID12163.disabled = false;
        }
        //15.シフト出勤状況
        if (await disabledNow(data)) {
          this.data.Recodes.FID12165.disabled = true;
        } else if (data.Recodes.FID12167.value == "該当なし") {
          this.data.Recodes.FID12165.value = ""; //値クリア
          this.data.Recodes.FID12165.disabled = true;
        } else {
          this.data.Recodes.FID12165.disabled = false;
        }
        //15.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12167.disabled = true;
        } else if (
          data.Recodes.FID12161.value == "" ||
          data.Recodes.FID12161.value == null ||
          data.Recodes.FID12163.value == "" ||
          data.Recodes.FID12163.value == null ||
          data.Recodes.FID12165.value == "" ||
          data.Recodes.FID12165.value == null
        ) {
          if (this.data.Recodes.FID12167.value !== "該当なし")
            this.data.Recodes.FID12167.value = "";
          this.data.Recodes.FID12167.disabled = true;
        } else if (
          data.Recodes.FID12161.value == "NG" ||
          data.Recodes.FID12163.value == "NG" ||
          data.Recodes.FID12165.value == "NG"
        ) {
          this.data.Recodes.FID12167.value = "×";
          this.data.Recodes.FID12167.disabled = false;
        } else {
          this.data.Recodes.FID12167.value = "〇";
          this.data.Recodes.FID12167.disabled = false;
        }
        //15.改善確認
        if (
          data.Recodes.FID12167.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != ""
        ) {
          if (data.Recodes.FID12167.value == "該当なし") {
            this.data.Recodes.FID12169.value = ""; //値クリア
          }
          this.data.Recodes.FID12169.disabled = true;
        } else {
          this.data.Recodes.FID12169.disabled = false;
        }
        //16.シート運用
        if (await disabledNow(data)) {
          this.data.Recodes.FID12175.disabled = true;
        } else {
          this.data.Recodes.FID12175.disabled = false;
        }
        //16.不備項目
        if (await disabledNow(data)) {
          this.data.Recodes.FID12177.disabled = true;
        } else {
          this.data.Recodes.FID12177.disabled = false;
        }
        //16.再発防止策の運用
        if (await disabledNow(data)) {
          this.data.Recodes.FID12179.disabled = true;
        } else {
          this.data.Recodes.FID12179.disabled = false;
          if (
            this.data.Recodes.FID12177.value == "無" &&
            (
              this.data.Recodes.FID12179.value == "OK" ||
              this.data.Recodes.FID12179.value == "NG" ||
              this.data.Recodes.FID12179.value == "" ||
              this.data.Recodes.FID12179.value == null
            )
          ) {
            this.data.Recodes.FID12179.value = "該当なし"
          } else if (
            this.data.Recodes.FID12177.value == "有" &&
            this.data.Recodes.FID12179.value == "該当なし"
          ) {
            this.data.Recodes.FID12179.value = ""
          }
        }
        //16.今回
        if (await disabledNow(data)) {
          this.data.Recodes.FID12181.disabled = true;
        } else if (
          data.Recodes.FID12175.value == "" ||
          data.Recodes.FID12175.value == null ||
          data.Recodes.FID12177.value == "" ||
          data.Recodes.FID12177.value == null ||
          data.Recodes.FID12179.value == "" ||
          data.Recodes.FID12179.value == null
        ) {
          if (this.data.Recodes.FID12181.value !== "該当なし")
            this.data.Recodes.FID12181.value = "";
          this.data.Recodes.FID12181.disabled = true;
        } else if (
          data.Recodes.FID12175.value == "NG" ||
          (
            data.Recodes.FID12177.value == "有" &&
            data.Recodes.FID12179.value == "NG"
          )
        ) {
          this.data.Recodes.FID12181.value = "×";
          this.data.Recodes.FID12181.disabled = false;
        } else {
          this.data.Recodes.FID12181.value = "〇";
          this.data.Recodes.FID12181.disabled = false;
        }
        //16.改善確認
        if (
          data.Recodes.FID12181.value != "×" ||
          data.Recodes.FID11988.value == "" ||
          data.Recodes.FID11988.value == null ||
          data.Recodes.FID11985.value != "" ||
          data.Recodes.FID12181.value == "該当なし"
        ) {
          this.data.Recodes.FID12183.disabled = true;
        } else {
          this.data.Recodes.FID12183.disabled = false;
        }
        //改善予定日確定/1回のみ
        if (disabledFID12023(data)) {
          this.data.Recodes.FID12023.disabled = true;
        } else {
          this.data.Recodes.FID12023.disabled = false;
        }
        //改善完了報告/1回のみ
        if (disabledFID11988(data)) {
          this.data.Recodes.FID11988.disabled = true;
        } else {
          this.data.Recodes.FID11988.disabled = false;
        }
        //GM確認/1回のみ
        if (disabledFID11985(data)) {
          this.data.Recodes.FID11985.disabled = true;
        } else {
          this.data.Recodes.FID11985.disabled = false;
        }
        //完了押下可能/1回のみ 必須情報：指導完了・情報入力完了・対応者名（面談者名）
        if (
          data.Recodes.FID11760.value != "" &&
          data.Recodes.FID11774.value != "" &&
          data.Recodes.FID11864.value != "" &&
          (data.Recodes.FID11772.value == "" ||
            data.Recodes.FID11772.value == null) &&
          data.Recodes.FID11775.value != ""
        ) {
          this.data.Recodes.FID11772.disabled = false;
        } else {
          this.data.Recodes.FID11772.disabled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
        }
        //データ取得処理
        //init処理
        this.initCheck12222();
        this.initCheck11758();
        this.initCheck11759();
        this.initValues11763();
        this.initValues11764();
        this.initValues11765();
        this.initFID11766();
        this.initValues11767();
        this.initValues11768();
        this.initCheck11864();
        this.initValues11844();
        this.initValues13377();
        this.initSubPage11984();
        this.initSubPage11836();
        this.initValues12267();
        this.initSubPage13630();
        this.initSubPage13710();
        this.initSubPage13711();
        this.initSubPage13712();
        this.initSubPage13713();
        this.initSubPage13714();

        //読み込み終了
        this.isLoading = false;
        // 対象外機能
        if (this.kaizenPlanBtn) {
          this.goSelectorID("FID12023");
          this.kaizenPlanBtn = false;
        }
        if (this.glBtn) {
          this.goSelectorID("FID11985");
          this.glBtn = false;
        }

        // 点検項目値設定
        this.setInspectionItem();

      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    calc11873sp(target, Recodes) {
      let total = statusCheck(target, Recodes);
      this.$set(target, "value", String(total));
      return total;
    },
    async onBtn11759() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID11759.value = date; //tag:日付+時間
      this.data.Recodes.FID11759.disabled = true; //1回だけ
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      //
      let value =
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      if (value == " ") {
        return "";
      }
      return value;
    },
    async onBtn11760() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID11760.value = date; //tag:日付+時間
    },
    calc11761sp(target, Recodes) {
      let total = watchFromID11761(target, Recodes);
      this.$set(target, "value", String(total));
      return total;
    },
    calc11762sp(target, Recodes) {
      let total = watchFromID11762(target, Recodes);
      this.$set(target, "value", String(total));
      return total;
    },
    async onBtn11864() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID11864.value = date; //tag:日付+時間
      this.data.Recodes.FID11864.disabled = true; //1回だけ
      // 対象外機能
      this.inputCompleteBtn = true;
      await this.save11935(); //送信処理
    },
    async save11935() {
      this.data.Recodes.FID11935.value = this.getDateTime();
      this.noBack = true;
      await this.send();
    },
    subPageAdd(FID, index) {
      this.isLoading = true;
      if (
        typeof this.data.Recodes[FID] == "undefined" ||
        typeof this.data.Recodes[FID].subReports == "undefined"
      ) {
        index = 0;
        this.data.Recodes[FID].subReports = [];
      }
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length;
      }
      let max = 20;
      if (this.data.Recodes[FID].subReports.length < max) {
        this.data.Recodes[FID].subReports.splice(index, 0, {});
      }
      this.isLoading = false;
    },
    subPageDel(FID, index) {
      //this.subInit = false;
      let min = 0;
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length - 1;
      }
      if (this.data.Recodes[FID].subReports.length > min) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          this.data.Recodes[FID].subReports.splice(index, 1)
        );
      }
      //this.subInit = true;
    },
    saveSubPage(FID, subindex, reports) {
      this.data.Recodes[FID].subReports.splice(subindex, 1, reports);
      if (reports.DeleteSubReports.length == 1) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          reports.DeleteSubReports.pop()
        );
      }
    },
    async onBtn12023() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      if (!(await collBtn12023No0(this.data, this.mainRecodes))) return; // 特殊関数の呼び出し
      this.data.Recodes.FID11984.subReports[
        this.data.Recodes.FID11984.subReports.length - 1
      ].Recodes.FID12026.value = this.data.Recodes.FID12023.value;
      this.data.Recodes.FID12204.value = "";
      // 対象外機能
      this.kaizenPlanBtn = true;
      await this.save11935(); //送信処理
    },
    inputRuleFID12023() {
      let data = this.data;
      if (disabledFID12023(data)) {
        return true;
      }
      return false;
    },
    async onBtn11988() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      const sendReult = await this.sendMail();
      if (!sendReult) {
        return;
      }
      //処理を記載
      if (!(await collBtn11988No0(this.data, this.mainRecodes))) return; // 特殊関数の呼び出し
      this.data.Recodes.FID11984.subReports[
        this.data.Recodes.FID11984.subReports.length - 1
      ].Recodes.FID11982.value = this.data.Recodes.FID11988.value;
      this.data.Recodes.FID12204.value = this.data.Recodes.FID11988.value;
      await this.save11771(); //送信処理
    },
    inputRuleFID11988() {
      let data = this.data;
      if (disabledFID11988(data)) {
        return true;
      }
      return false;
    },
    async onBtn11985() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      if (!(await collBtn11985No0(this.data, this.mainRecodes))) return; // 特殊関数の呼び出し
      this.data.Recodes.FID11984.subReports[
        this.data.Recodes.FID11984.subReports.length - 1
      ].Recodes.FID11983.value = this.getDate();
      if (
        typeof this.data.Recodes.FID11985 != "undefined" &&
        this.data.Recodes.FID11985.value == ""
      ) {
        this.subPageAdd("FID11984");
        await this.$nextTick();
        this.data.Recodes.FID12023.value = "";
        this.data.Recodes.FID11988.value = "";
        this.data.Recodes.FID11984.subReports[
          this.data.Recodes.FID11984.subReports.length - 1
        ].Recodes.FID12027.value = this.getDate();
      }
      // 対象外機能
      this.glBtn = true;
      await this.save11935(); //送信処理
    },
    inputRuleFID11985() {
      let data = this.data;
      if (disabledFID11985(data)) {
        return true;
      }
      return false;
    },
    getDate() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      return years + "-" + month + "-" + days;
    },
    changeSubPageFID11836(i, j) {
      let [reportID1, reportID2] = [
        this.$refs.subPage11836[i].data.ReportID,
        this.$refs.subPage11836[j].data.ReportID,
      ];
      let tmp = this.$refs.subPage11836[i].data;
      this.$refs.subPage11836[i].data = this.$refs.subPage11836[j].data;
      this.$refs.subPage11836[j].data = tmp;
      //レポートIDはそのまま維持する
      this.$refs.subPage11836[i].data.ReportID = reportID1;
      this.$refs.subPage11836[j].data.ReportID = reportID2;
    },
    async sendSubReport11836() {
      if (
        typeof this.$refs.subPage11836 != "undefined" &&
        typeof this.$refs.subPage11836.length != "undefined"
      ) {
        for (let i = 0; i < this.$refs.subPage11836.length; i++) {
          await this.$refs.subPage11836[i].send();
        }
      }
    },
    changeSubPageFID13630(i, j) {
      let [reportID1, reportID2] = [
        this.$refs.subPage13630[i].data.ReportID,
        this.$refs.subPage13630[j].data.ReportID,
      ];
      let tmp = this.$refs.subPage13630[i].data;
      this.$refs.subPage13630[i].data = this.$refs.subPage13630[j].data;
      this.$refs.subPage13630[j].data = tmp;
      //レポートIDはそのまま維持する
      this.$refs.subPage13630[i].data.ReportID = reportID1;
      this.$refs.subPage13630[j].data.ReportID = reportID2;
    },
    async sendSubReport13630() {
      if (
        typeof this.$refs.subPage13630 != "undefined" &&
        typeof this.$refs.subPage13630.length != "undefined"
      ) {
        for (let i = 0; i < this.$refs.subPage13630.length; i++) {
          await this.$refs.subPage13630[i].send();
        }
      }
    },
    changeSubPageFID13710(i, j) {
      let [reportID1, reportID2] = [
        this.$refs.subPage13710[i].data.ReportID,
        this.$refs.subPage13710[j].data.ReportID,
      ];
      let tmp = this.$refs.subPage13710[i].data;
      this.$refs.subPage13710[i].data = this.$refs.subPage13710[j].data;
      this.$refs.subPage13710[j].data = tmp;
      //レポートIDはそのまま維持する
      this.$refs.subPage13710[i].data.ReportID = reportID1;
      this.$refs.subPage13710[j].data.ReportID = reportID2;
    },
    async sendSubReport13710() {
      if (
        typeof this.$refs.subPage13710 != "undefined" &&
        typeof this.$refs.subPage13710.length != "undefined"
      ) {
        for (let i = 0; i < this.$refs.subPage13710.length; i++) {
          await this.$refs.subPage13710[i].send();
        }
      }
    },
    changeSubPageFID13711(i, j) {
      let [reportID1, reportID2] = [
        this.$refs.subPage13711[i].data.ReportID,
        this.$refs.subPage13711[j].data.ReportID,
      ];
      let tmp = this.$refs.subPage13711[i].data;
      this.$refs.subPage13711[i].data = this.$refs.subPage13711[j].data;
      this.$refs.subPage13711[j].data = tmp;
      //レポートIDはそのまま維持する
      this.$refs.subPage13711[i].data.ReportID = reportID1;
      this.$refs.subPage13711[j].data.ReportID = reportID2;
    },
    async sendSubReport13711() {
      if (
        typeof this.$refs.subPage13711 != "undefined" &&
        typeof this.$refs.subPage13711.length != "undefined"
      ) {
        for (let i = 0; i < this.$refs.subPage13711.length; i++) {
          await this.$refs.subPage13711[i].send();
        }
      }
    },
    changeSubPageFID13712(i, j) {
      let [reportID1, reportID2] = [
        this.$refs.subPage13712[i].data.ReportID,
        this.$refs.subPage13712[j].data.ReportID,
      ];
      let tmp = this.$refs.subPage13712[i].data;
      this.$refs.subPage13712[i].data = this.$refs.subPage13712[j].data;
      this.$refs.subPage13712[j].data = tmp;
      //レポートIDはそのまま維持する
      this.$refs.subPage13712[i].data.ReportID = reportID1;
      this.$refs.subPage13712[j].data.ReportID = reportID2;
    },
    async sendSubReport13712() {
      if (
        typeof this.$refs.subPage13712 != "undefined" &&
        typeof this.$refs.subPage13712.length != "undefined"
      ) {
        for (let i = 0; i < this.$refs.subPage13712.length; i++) {
          await this.$refs.subPage13712[i].send();
        }
      }
    },
    changeSubPageFID13713(i, j) {
      let [reportID1, reportID2] = [
        this.$refs.subPage13713[i].data.ReportID,
        this.$refs.subPage13713[j].data.ReportID,
      ];
      let tmp = this.$refs.subPage13713[i].data;
      this.$refs.subPage13713[i].data = this.$refs.subPage13713[j].data;
      this.$refs.subPage13713[j].data = tmp;
      //レポートIDはそのまま維持する
      this.$refs.subPage13713[i].data.ReportID = reportID1;
      this.$refs.subPage13713[j].data.ReportID = reportID2;
    },
    async sendSubReport13713() {
      if (
        typeof this.$refs.subPage13713 != "undefined" &&
        typeof this.$refs.subPage13713.length != "undefined"
      ) {
        for (let i = 0; i < this.$refs.subPage13713.length; i++) {
          await this.$refs.subPage13713[i].send();
        }
      }
    },
    changeSubPageFID13714(i, j) {
      let [reportID1, reportID2] = [
        this.$refs.subPage13714[i].data.ReportID,
        this.$refs.subPage13714[j].data.ReportID,
      ];
      let tmp = this.$refs.subPage13714[i].data;
      this.$refs.subPage13714[i].data = this.$refs.subPage13714[j].data;
      this.$refs.subPage13714[j].data = tmp;
      //レポートIDはそのまま維持する
      this.$refs.subPage13714[i].data.ReportID = reportID1;
      this.$refs.subPage13714[j].data.ReportID = reportID2;
    },
    async sendSubReport13714() {
      if (
        typeof this.$refs.subPage13714 != "undefined" &&
        typeof this.$refs.subPage13714.length != "undefined"
      ) {
        for (let i = 0; i < this.$refs.subPage13714.length; i++) {
          await this.$refs.subPage13714[i].send();
        }
      }
    },
    async onBtn11772() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID11772.value = date; //tag:日付+時間
      await this.save11771(); //送信処理
    },
    async save11771() {
      this.data.Recodes.FID11771.value = this.getDateTime();
      await this.send();
    },
    async onBtn12255() {
      //処理を記載
      this.goSelectorID("FID11758");
    },
    async onBtn13151() {
      //処理を記載
      this.goSelectorID("FID11772");
    },
    goSelectorID(id) {
      //要素が無いと移動できないため
      this.isLoading = false;
      this.$nextTick(() => {
        const elements = document.querySelectorAll("*");
        // idがある要素のidを配列に格納
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].id == id) {
            this.$vuetify.goTo(elements[i], { offset: 100 });
            break;
          }
        }
      });
    },
    initCheck12222() {
      if (typeof this.data.Recodes.FID12222 == "undefined") {
        this.data.Recodes.FID12222 = { value: "", formType: 3 };
      }
      if (this.data.Recodes.FID12222.value == "") {
        this.data.Recodes.FID12222.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours() + 9
        )
          .toISOString()
          .match(/^\d{4}-\d{2}/g)
          .pop();
      }
    },
    initCheck11758() {
      if (typeof this.data.Recodes.FID11758 == "undefined") {
        this.data.Recodes.FID11758 = { value: "", formType: 3 };
      }
      if (this.data.Recodes.FID11758.value == "") {
        this.data.Recodes.FID11758.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours() + 9
        )
          .toISOString()
          .match(/^\d{4}-\d{2}-\d{2}/g)
          .pop();
      }
    },
    initCheck11759() {
      if (typeof this.data.Recodes.FID11759 == "undefined") {
        this.data.Recodes.FID11759 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID11759.value != "") {
        this.data.Recodes.FID11759.disabled = true; //1回だけ
      }
    },
    initValues11763() {
      if (
        this.data.Recodes.FID11763.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11265") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11265") != null
      ) {
        this.data.Recodes.FID11763.value = localStorage.getItem(
          "kakanaiValueFID11265"
        );
        localStorage.removeItem("kakanaiValueFID11265");
      }
    },
    initValues11764() {
      if (
        this.data.Recodes.FID11764.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11271") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11271") != null
      ) {
        this.data.Recodes.FID11764.value = localStorage.getItem(
          "kakanaiValueFID11271"
        );
        localStorage.removeItem("kakanaiValueFID11271");
      }
    },
    initValues11765() {
      if (
        this.data.Recodes.FID11765.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11268") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11268") != null
      ) {
        this.data.Recodes.FID11765.value = localStorage.getItem(
          "kakanaiValueFID11268"
        );
        localStorage.removeItem("kakanaiValueFID11268");
      }
    },
    initValues12267() {
      // if (
      //   this.data.Recodes.FID12267.value == "" &&
      //   typeof localStorage.getItem("kakanaiValueFID11273") != "undefined" &&
      //   localStorage.getItem("kakanaiValueFID11273") != null
      // ) {
      //   this.data.Recodes.FID12267.value = localStorage.getItem(
      //     "kakanaiValueFID11273"
      //   );
      //   localStorage.removeItem("kakanaiValueFID11273");
      //   if (this.data.Recodes.FID12267.value) {
      //     // 6.7 今回
      //     this.data.Recodes.FID12140.value = "該当なし";
      //     // 6.8 今回
      //     this.data.Recodes.FID12154.value = "該当なし";
      //     // 6.9 今回
      //     this.data.Recodes.FID12167.value = "該当なし";
      //   }
      // }
    },
    async initFID11766() {
      if (this.data.Recodes.FID11766.value == "") {
        // this.data.Recodes.FID11766.value =
        //   this.$store.state.user.attributes.name;
        let data = {
          name: this.$store.state.user.attributes.name,
          PageID: 1654,
        };
        [this.UserRecodes, ] = await api.UserDetail(
          data,
          this.UserRecodes
        );
        this.data.Recodes.FID11766.value = this.UserRecodes.FID13317.value;
      }
    },
    initValues11767() {
      if (
        this.data.Recodes.FID11767.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11266") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11266") != null
      ) {
        this.data.Recodes.FID11767.value = localStorage.getItem(
          "kakanaiValueFID11266"
        );
        localStorage.removeItem("kakanaiValueFID11266");
      }
    },
    initValues11768() {
      if (
        this.data.Recodes.FID11768.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11269") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11269") != null
      ) {
        this.data.Recodes.FID11768.value = localStorage.getItem(
          "kakanaiValueFID11269"
        );
        localStorage.removeItem("kakanaiValueFID11269");
      }
    },
    initCheck11864() {
      if (typeof this.data.Recodes.FID11864 == "undefined") {
        this.data.Recodes.FID11864 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID11864.value != "") {
        this.data.Recodes.FID11864.disabled = true; //1回だけ
      }
    },
    initValues11844() {
      if (
        this.data.Recodes.FID11844.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11267") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11267") != null
      ) {
        this.data.Recodes.FID11844.value = localStorage.getItem(
          "kakanaiValueFID11267"
        );
        localStorage.removeItem("kakanaiValueFID11267");
      }
    },
    initValues13377() {
      if (
        this.data.Recodes.FID13377.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID13155") != "undefined" &&
        localStorage.getItem("kakanaiValueFID13155") != null
      ) {
        this.data.Recodes.FID13377.value = localStorage.getItem(
          "kakanaiValueFID13155"
        );
        localStorage.removeItem("kakanaiValueFID13155");
      }
    },
    initSubPage11984() {
      for (let i = this.data.Recodes.FID11984.subReports.length; i < 1; i++) {
        this.data.Recodes.FID11984.subReports.push({});
      }
    },
    initSubPage11836() {
      for (let i = this.data.Recodes.FID11836.subReports.length; i < 1; i++) {
        this.data.Recodes.FID11836.subReports.push({});
      }
    },
    initSubPage13630() {
      for (let i = this.data.Recodes.FID13630.subReports.length; i < 1; i++) {
        this.data.Recodes.FID13630.subReports.push({});
      }
    },
    initSubPage13710() {
      for (let i = this.data.Recodes.FID13710.subReports.length; i < 1; i++) {
        this.data.Recodes.FID13710.subReports.push({});
      }
    },
    initSubPage13711() {
      for (let i = this.data.Recodes.FID13711.subReports.length; i < 1; i++) {
        this.data.Recodes.FID13711.subReports.push({});
      }
    },
    initSubPage13712() {
      for (let i = this.data.Recodes.FID13712.subReports.length; i < 1; i++) {
        this.data.Recodes.FID13712.subReports.push({});
      }
    },
    initSubPage13713() {
      for (let i = this.data.Recodes.FID13713.subReports.length; i < 1; i++) {
        this.data.Recodes.FID13713.subReports.push({});
      }
    },
    initSubPage13714() {
      for (let i = this.data.Recodes.FID13714.subReports.length; i < 1; i++) {
        this.data.Recodes.FID13714.subReports.push({});
      }
    },
    setAutoValue11841(save) {
      //v1レスポンスに依存した処理
      if (
        this.data.Recodes.FID11841.value != "" ||
        typeof save.value != "object" ||
        save.value.length < 1
      ) {
        return;
      }
      this.data.Recodes.FID11841.value = save.value[0];
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      if (typeof this.$refs.subPage11984 != "undefined") {
        for (let i = 0; i < this.$refs.subPage11984.length; i++) {
          let tmp = await this.$refs.subPage11984[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      await this.sendSubReport11836();
      if (typeof this.$refs.subPage11836 != "undefined") {
        for (let i = 0; i < this.$refs.subPage11836.length; i++) {
          let tmp = await this.$refs.subPage11836[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      await this.sendSubReport13630();
      if (typeof this.$refs.subPage13630 != "undefined") {
        for (let i = 0; i < this.$refs.subPage13630.length; i++) {
          let tmp = await this.$refs.subPage13630[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      await this.sendSubReport13710();
      if (typeof this.$refs.subPage13710 != "undefined") {
        for (let i = 0; i < this.$refs.subPage13710.length; i++) {
          let tmp = await this.$refs.subPage13710[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      await this.sendSubReport13711();
      if (typeof this.$refs.subPage13711 != "undefined") {
        for (let i = 0; i < this.$refs.subPage13711.length; i++) {
          let tmp = await this.$refs.subPage13711[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      await this.sendSubReport13712();
      if (typeof this.$refs.subPage13712 != "undefined") {
        for (let i = 0; i < this.$refs.subPage13712.length; i++) {
          let tmp = await this.$refs.subPage13712[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      await this.sendSubReport13713();
      if (typeof this.$refs.subPage13713 != "undefined") {
        for (let i = 0; i < this.$refs.subPage13713.length; i++) {
          let tmp = await this.$refs.subPage13713[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      await this.sendSubReport13714();
      if (typeof this.$refs.subPage13714 != "undefined") {
        for (let i = 0; i < this.$refs.subPage13714.length; i++) {
          let tmp = await this.$refs.subPage13714[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
        this.setAutoValue11841(save);

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        // 対象外機能
        if (this.inputCompleteBtn) {
          this.inputCompleteBtn = false;
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          this.isLoading = false;
          this.noBack = false;
          this.$router.replace({
            path: "/patrolReport/create/" + save.reportID,
          });
          return;
        }
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      //金銭点検表の取得
      const moneyCheckPageID = 1655;
      const moneyCheckData = {
        PageID: moneyCheckPageID,
        orderBy: "",
        search: [],
      };
      moneyCheckData.search.push({
        form_id: 11842,
        value: this.data.Recodes.FID11841.value,
        option: "match",
      });
      const moneyCheckReports = await api.ReportSearch(moneyCheckData);
      if (moneyCheckReports && moneyCheckReports.length) {
        for (let i = 0; i < moneyCheckReports.length; i++) {
          const delMoneyCheckReports = {
            DeleteSubReports: [],
            PageID: moneyCheckPageID,
            Recodes: moneyCheckReports[i].Recodes,
            ReportID: moneyCheckReports[i].ReportID,
          };
          await api.DelReport(delMoneyCheckReports);
        }
      }
      //在庫確認表の取得
      const stockCheckPageID = 1656;
      const stockCheckData = {
        PageID: stockCheckPageID,
        orderBy: "",
        search: [],
      };
      stockCheckData.search.push({
        form_id: 11843,
        value: this.data.Recodes.FID11841.value,
        option: "match",
      });
      const stockCheckReports = await api.ReportSearch(stockCheckData);
      if (stockCheckReports && stockCheckReports.length) {
        for (let i = 0; i < moneyCheckReports.length; i++) {
          const delStockCheckReports = {
            DeleteSubReports: [],
            PageID: stockCheckPageID,
            Recodes: stockCheckReports[i].Recodes,
            ReportID: stockCheckReports[i].ReportID,
          };
          await api.DelReport(delStockCheckReports);
        }
      }
      //
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
    async getSSMaster() {
      const data = {
        PageID: 1661,
        orderBy: "",
        search: [],
      };
      let report = { ReportID: null };
      data.search.push({
        form_id: 11265,
        value: this.data.Recodes.FID11763.value, //SSコード
        option: "match",
      });
      const response = await api.GetMasterV2(data);
      if (response.data.length == 1) {
        response.data[0].PageID = data.PageID;
        report = response.data[0];
      }
      return report;
    },
    async sendMail() {
      if (
        !confirm(
          "改善完了報告を行いますか？\n※GMに「改善完了」のメールが送信されます。"
        )
      ) {
        return false;
      }
      // SSマスタを取得してGMメールアドレス・SS名を取得する
      const ssMaster = await this.getSSMaster();
      // エラーチェック
      let errorMessage = "";
      if (!ssMaster.ReportID) {
        errorMessage = "SSマスタが取得できませんでした。";
      } else if (!ssMaster.Recodes.FID13157.value) {
        errorMessage = "GMのメールアドレスが取得できませんでした。";
      } else if (!ssMaster.Recodes.FID13157.value.includes("@")) {
        errorMessage = "GMのメールアドレス形式が不正なメールアドレス形式です。";
      }
      if (errorMessage) {
        return confirm(errorMessage + "メールを送信せずに登録しますか？");
      }
      // 日付の整形
      const splitDate = this.data.Recodes.FID11758.value.split("-");
      let dateString = "";
      if (splitDate.length == 3) {
        dateString = splitDate[1] + "月" + splitDate[2] + "日";
      }
      //メール送信処理
      let mailMessage =
        "【SSコード】" + this.data.Recodes.FID11763.value + "\n";
      mailMessage += "【SS名】" + this.data.Recodes.FID11767.value + "\n\n";
      mailMessage += dateString ? dateString + "に実施した" : "";
      mailMessage +=
        "巡回レポートの改善が完了いたしました。対応をお願い致します。";
      const mailRequest = {
        to: [],
        toAddress: [ssMaster.Recodes.FID13157.value],
        cc: [],
        ccAddress: [],
        subject: "【報告】巡回レポートの改善が完了しました",
        message: mailMessage,
      };
      const result = await api_extra.SendMail(mailRequest);
      if (!result || result.status == "failed") {
        return confirm(
          "メールの送信に失敗しました。メールを送信せずに登録しますか？"
        );
      }
      return true;
    },
    //分解整備⑦：今回該当なしの押下処理
    onBtn12140() {
      if (this.data.Recodes.FID12140.value !== "該当なし") {
        //⑦の今回該当なしをクリック時に⑧⑨を該当なしにする
        this.data.Recodes.FID12154.value = "該当なし";
        this.data.Recodes.FID12167.value = "該当なし";
      }
    },
    //点検項目選択時の処理
    async handleInspectionItemChange(newValue) {
      if (newValue != null && newValue != "") {
        let check = await this.checkValidation();
        if (!check) {
          return;
        }
        //各点検項目の表示制御
        this.isShowInspection1 = newValue == "0" || newValue == "1";
        this.isShowInspection2 = newValue == "0" || newValue == "2";
        this.isShowInspection3 = newValue == "0" || newValue == "3";
        this.isShowInspection4 = newValue == "0" || newValue == "4";
        this.isShowInspection5 = newValue == "0" || newValue == "5";
        this.isShowInspection6 = newValue == "0" || newValue == "6";
        this.isShowInspection7 = newValue == "0" || newValue == "7";
        this.isShowInspection8 = newValue == "0" || newValue == "8";
      }
    },
    classRequired(field) {
      if (field.disabled || this.allDisabled11969) {
        return ''
      }
      return field.value?'require-ok':'require-label'
    },
    inputColor(field) {
      let data = this.data;
      return spInputColor(data, field, this.allDisabled11969)
    },
    // 金銭点検チェックから現金差異を取得
    async importMoneyCheck() {
      this.validMoney = false
      if (!confirm("現金差異チェックから現金差異を取得します。\nよろしいですか？")) {
        return
      }
      // 金銭点検チェックの取得
      const moneyCheckPageID = 1655;
      const moneyCheckData = {
        PageID: moneyCheckPageID,
        orderBy: "",
        search: [],
      };
      moneyCheckData.search.push({
        form_id: 11842,
        value: this.data.Recodes.FID11841.value,
        option: "match",
      });
      const moneyCheckReports = await api.ReportSearch(moneyCheckData);
      console.log(moneyCheckReports)
      if (moneyCheckReports && moneyCheckReports.length) {
        this.data.Recodes.FID12033.value = String(moneyCheckReports[0].Recodes.FID11671.value)
        if (this.data.Recodes.FID12033.value != "0") {
          this.data.Recodes.FID12032.value = "有"
        } else if (this.data.Recodes.FID12033.value == "0") {
          this.data.Recodes.FID12032.value = "無"
        }
      } else {
        this.validMoney = true
      }
    },
    // 在庫差異チェックからタイヤ差異とバッテリー差異を取得
    async importStockCheck() {
      this.validStock = false
      if (!confirm("在庫差異チェックからタイヤ差異個数とバッテリー差異個数を取得します。\nよろしいですか？")) {
        return
      }
      // 在庫差異チェックの取得
      const stockCheckPageID = 1656;
      const stockCheckData = {
        PageID: stockCheckPageID,
        orderBy: "",
        search: [],
      };
      stockCheckData.search.push({
        form_id: 11843,
        value: this.data.Recodes.FID11841.value,
        option: "match",
      });
      const stockCheckReports = await api.ReportSearch(stockCheckData);
      let diff_tire = 0
      let diff_battery = 0
      if (stockCheckReports && stockCheckReports.length) {
        for (let i = 0; i < stockCheckReports.length; i++) {
          for (let j = 0; j < stockCheckReports[i].Recodes.FID11753.subReports.length; j++) {
            console.log(stockCheckReports[i].Recodes.FID11753.subReports[j].Recodes.FID11820.value)
            if (stockCheckReports[i].Recodes.FID11753.subReports[j].Recodes.FID11820.value == "タイヤ") {
              diff_tire += parseInt(stockCheckReports[i].Recodes.FID11753.subReports[j].Recodes.FID11750.value)
            }
            if (stockCheckReports[i].Recodes.FID11753.subReports[j].Recodes.FID11820.value == "バッテリー") {
              diff_battery += parseInt(stockCheckReports[i].Recodes.FID11753.subReports[j].Recodes.FID11750.value)
            }
          }
        }
        this.data.Recodes.FID12095.value = String(diff_tire)
        this.data.Recodes.FID12109.value = String(diff_battery)
        if (this.data.Recodes.FID12095.value != "0") {
          this.data.Recodes.FID12094.value = "有"
        } else if (this.data.Recodes.FID12095.value == "0") {
          this.data.Recodes.FID12094.value = "無"
        }
        if (this.data.Recodes.FID12109.value != "0") {
          this.data.Recodes.FID12108.value = "有"
        } else if (this.data.Recodes.FID12109.value == "0") {
          this.data.Recodes.FID12108.value = "無"
        }
      } else {
        this.validStock = true
      }
    },
    // ナビメニューからの遷移用
    moveToPatrolReport() {
      this.setInspectionItem();
    },
    moveToMoneyCheck() {
      this.$refs.patrolreportCreateSpecial.moveMoneyCheck();
    },
    moveToStockCheck() {
      this.$refs.patrolreportCreateSpecial.moveStockCheck();
    },
    setInspectionItem() {
      this.inspectionItem = this.$store.state.movePatrolReportInspectionItem;
      const newValue = this.inspectionItem;
      if (newValue != null && newValue != "") {
        //各点検項目の表示制御
        this.isShowInspection1 = newValue == "0" || newValue == "1";
        this.isShowInspection2 = newValue == "0" || newValue == "2";
        this.isShowInspection3 = newValue == "0" || newValue == "3";
        this.isShowInspection4 = newValue == "0" || newValue == "4";
        this.isShowInspection5 = newValue == "0" || newValue == "5";
        this.isShowInspection6 = newValue == "0" || newValue == "6";
        this.isShowInspection7 = newValue == "0" || newValue == "7";
        this.isShowInspection8 = newValue == "0" || newValue == "8";
      }
      store.commit("clearMovePatrolReportInspectionItem");
    },
  },
};
</script>

<style>
/* v-btn-toggleのdisabled時カーソル変更 */
.v-btn-toggle[disabled] {
  cursor: not-allowed;
}
/* v-btn-toggleのdisabled時背景色変更 */
.radio-group .v-btn.v-btn--active.disabled-btn {
  background-color: #b76e00 !important;
  color: white !important;
}
</style>
